import {Grid, Typography} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import React, {Component} from 'react';
import CollectionCrud from '../../../common/Components/CollectionCrud';
import TextInput from '../../../common/Components/TextInput';

class Finition extends Component {

  /**
   * Title de la modal de suppression
   * @return {[type]} [description]
   */
  getDeleteModalTitle(finition) {
    return <div><SettingsIcon/> <span>{'Suppression de la finition : ' + (finition ? finition.libelle : '')}</span>
    </div>;
  }

  getDeleteModalContent() {
    return <Typography>{'Êtes vous sûr de vouloir supprimer cette finition ?'}</Typography>;
  }

  getCreateUpdateModalTitle(finition) {
    return <div><SettingsIcon/>
      <span>{!!finition ? 'Édition de la finition : ' + finition.libelle : 'Création d\'une nouvelle finition'}</span>
    </div>;
  }


  /**
   * Retourne le formulaire
   * @returns {*}
   */
  getCreateUpdateModalContent(finition, finitionStore, onChangeHandler) {
    let {libelle} = finition;

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextInput
            id="libellefinition"
            label="Libelle"
            value={libelle}
            margin="normal"
            collectionStore={finitionStore}
            name='libelle'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  /**
   * retourne la configuration des cellules
   * @return {[type]} [description]
   */
  getCellsConfig() {
    return [
      {
        datakey: "libelle",
        title: "Libelle",
        sortable: true,
        searchable: true,
      }
    ]
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    return (
      <CollectionCrud
        collectionName={'finitions'}
        showBtnEdit={true}
        showBtnAdd={true}
        showBtnDelete={true}
        deleteModalTitle={this.getDeleteModalTitle}
        deleteModalContent={this.getDeleteModalContent}
        createUpdateModalTitle={this.getCreateUpdateModalTitle}
        createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
        datatableConfig={{
          showPagination: true,
          showSearch: true,
          defaultSort: "libelle",
          rowsPerPageOptions:[10, 25, 50]
        }}
        cellsConfig={this.getCellsConfig()}
      />
    );
  }
}

export default Finition
