import {Button, Card, CardContent, CircularProgress, Grid, Typography} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import TextInput from "../../common/Components/TextInput";
import MobilierCss from "./css/MobilierCss";

class UpdateOptions extends Component {

  onClickHandler(action, value, name) {
    this.props.onChangeHandler(name, {
      target: {
        name,
        value: action === 'minus' ? (value > 0 ? value - 1 : 0) : value + 1
      }
    })
  }

  getOptions() {
    const {classes, mobiliersStore, onChangeHandler, mobilier} = this.props,
      options = [
        {
          libelle: 'Banc',
          colonne: 'options_banc',
        },
        {
          libelle: 'Corbeille',
          colonne: 'options_corbeille',
        },
        {
          libelle: 'Cadre horaire',
          colonne: 'options_cadre_horaire',
        },
        {
          libelle: 'Rampe courtoisie',
          colonne: 'options_rampe_courtoisie',
        },
        {
          libelle: 'Bandeau frontal',
          colonne: 'options_bandeau_frontal',
        },
        {
          libelle: 'Bandeau lateral',
          colonne: 'options_bandeau_lateral',
        }
      ];
    let result = [];

    result.push(
      options.map( option => {
        return (
          <Grid item xs={4} key={option.colonne}>
            <Grid container spacing={4}>
              <Grid item xs={5}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.optionsLibelle}
                >
                  {option.libelle}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Button
                  className={classes.optionsButton}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={this.onClickHandler.bind(this, 'minus', mobilier[option.colonne], option.colonne)}
                >
                  <Remove/>
                </Button>
              </Grid>
              <Grid item xs={2}>
                <TextInput
                  id={option.colonne}
                  type="text"
                  inputProps={{style: {textAlign: 'center'}}}
                  className={classes.optionsInput}
                  value={mobilier[option.colonne]}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name={option.colonne}
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  className={classes.optionsButton}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={this.onClickHandler.bind(this, 'plus', mobilier[option.colonne], option.colonne)}
                >
                  <Add/>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )
      })
    );

    return result
  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {classes, mobiliersStore, onChangeHandler, mobilier} = this.props;

    if (!mobilier) return (
      <Grid item xs={12} style={{textAlign: 'center'}}>
        <CircularProgress/>
      </Grid>
    );
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} lg={12}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={9}>
                  <Grid container spacing={4}>
                    {this.getOptions()}
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <TextInput
                    id="options_autres"
                    multiline
                    rows={3}
                    rowsMax={3}
                    type="text"
                    label="Autres"
                    className={classes.input}
                    value={mobilier.options_autres}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='options_autres'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }
}

UpdateOptions = withStyles(MobilierCss)(UpdateOptions);

UpdateOptions = connect((store) => {
  return {}
})(UpdateOptions);

UpdateOptions.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  mobilier: PropTypes.object.isRequired
};

export default UpdateOptions
