import {axiosClient} from '../../../../axios';
import {axiosErrorHandler} from '../../../../reducers/notificationReducer';

export function getSemainesOuvrees(dispatch, cb) {
  axiosClient.get('mobiliers/semaines')
    .then(response => {
      cb(response.data)
    })
    .catch(err => axiosErrorHandler(dispatch, err))
}

export function getMobiliersBySemaine(dispatch, date_debut, date_fin, cb) {
  axiosClient.get('mobiliers', {params: {date_debut, date_fin}})
    .then(response => {
      cb(response.data.results)
    })
    .catch(err => axiosErrorHandler(dispatch, err))
}