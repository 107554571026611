import {Grid, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {Component} from 'react';
import ContenuSemaine from "./ContenuSemaine";
import HomeCss from './css/HomeCss';
import SelecteurSemaines from "./SelecteurSemaines";

class Home extends Component {
  selecteurSemainesCallback(selectedWeek) {
    this.setState({
      selectedWeek: selectedWeek
    })
  }

  /**
   * Appelle le composant SelecteurSemaines
   * @returns {*}
   */
  getSelecteurSemaines() {
    return (
      <SelecteurSemaines
        callback={this.selecteurSemainesCallback.bind(this)}
      />
    )
  }

  /**
   * Appelle le composant ContenuSemaine
   * @returns {*}
   */
  getContenuSemaine() {
    if (this.state) return (
      <ContenuSemaine
        selectedWeek={this.state.selectedWeek}
      />
    )
  }

  /**
   * Rendu global
   * @returns {*}
   */
  render() {
    const {classes} = this.props;

    return (
      <Grid container spacing={6} className={classes.container}>
        <Typography
          variant="button"
          gutterBottom
          className={classes.usersTitle}
        >
          Activités de la semaine
        </Typography>
        {this.getSelecteurSemaines()}
        {this.getContenuSemaine()}
      </Grid>
    );
  }
}

Home = withStyles(HomeCss)(Home);

export default Home
