import {Grid, Tab, Tabs, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {isMobile, isTablet} from "react-device-detect";
import Computer from '@material-ui/icons/Computer';
import LocationOn from '@material-ui/icons/LocationOn';
import Settings from '@material-ui/icons/Settings';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Schedule from '@material-ui/icons/Schedule';
import Edit from '@material-ui/icons/Edit';
import BubbleChart from '@material-ui/icons/BubbleChart';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import {collectionActions, loadCollectionAttribute} from "../../../reducers/collectionsReducer";
import ModalFullScreen from '../../common/Components/Modal/FullScreen';
import {updatePhoto} from './actions/MobilierActions';
import MobilierCss from './css/MobilierCss';
import UpdateConfigurations from "./UpdateConfigurations";
import UpdateGeneral from "./UpdateGeneral";
import UpdateGeolocalisation from "./UpdateGeolocalisation";
import UpdateOptions from "./UpdateOptions";
import UpdatePhoto from "./UpdatePhoto";
import UpdateSchema from "./UpdateSchema";
import UpdateSuivi from "./UpdateSuivi";

function TabContainer({children, dir}) {
  return (
    <Typography component="div" dir={dir} style={{padding: 8 * 3}}>
      {children}
    </Typography>
  );
}

class Update extends Component {
  state = {
    schemaUpdated: false,
    mobilier: {},
    file: null,
    imagePreviewUrl: null,
    tabValue: 0,
    open: false,
    mobilier_uuid: null
  };

  componentDidMount() {
    loadCollectionAttribute(this.props.dispatch, 'list', 'code_proginovs', this.props.code_proginovsStore);
  }

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const {mobiliersStore} = nextProps;
    let mobilierProps = mobiliersStore.detail,
      mobilierState = prevState.mobilier,
      result = {};

    // Gestion de l'alimentation du state avec les données du mobilier

    // Si le mobiliersStore n'a pas de 'detail' alors on va chercher la ressource afin de l'alimenter => cas de la Home
    if (!mobilierProps || (!!mobilierProps && mobilierProps.uuid !== nextProps.mobilier_uuid)) {
      loadCollectionAttribute(nextProps.dispatch, 'detail', 'mobiliers', nextProps.mobiliersStore, {uuid: nextProps.mobilier_uuid}, () => {
        result = {
          ...result,
          mobilier_uuid: mobilierProps.uuid
        }
      });
    }

    // Si mobiliersStore a un 'detail' alors on alimente le state avec
    if (!mobilierState || (mobilierProps && mobilierState.uuid !== mobilierProps.uuid)) {
      result = {
        ...result,
        mobilier: mobiliersStore.detail,
        file: null,
        imagePreviewUrl: null,
      }
    }

    // Gestion des actions liées à l'ouverture et à la fermeture de la modale
    if (nextProps.open !== prevState.open) {
      result = {
        ...result,
        open: nextProps.open
      };

      if (prevState.open && !nextProps.open) {
        result = {
          ...result,
          mobilier: {},
          tabValue: 0
        };
        collectionActions(nextProps.dispatch, 'mobiliers', 'RESET_ERRORS');
        collectionActions(nextProps.dispatch, 'mobiliers', 'RESET_DETAIL');
      }
    }

    return result;
  }

  /**
   * Gestion du changement d'onglet
   * @param event
   * @param value
   */
  handleChangeTab = (event, value) => {
    this.setState({tabValue: value});
  };

  handleChangeTabIndex = index => {
    this.setState({tabValue: index});
  };

  /**
   * Gestion de la modification des champs "text" et "date"
   * @param name
   * @param event
   */
  onChangeHandler = (name, event) => {
    let nullables_values = {
      'config_existant_type_uuid': {
        'config_existant_gamme_uuid': null,
        'config_existant_configuration_uuid': null,
        'config_existant_intitule_uuid': null,
        'config_existant_designation_uuid': null,
      },
      'config_existant_gamme_uuid': {
        'config_existant_configuration_uuid': null,
        'config_existant_intitule_uuid': null,
        'config_existant_designation_uuid': null,
      },
      'config_existant_configuration_uuid': {
        'config_existant_intitule_uuid': null,
        'config_existant_designation_uuid': null,
      },
      'config_existant_intitule_uuid': {
        'config_existant_designation_uuid': null,
      },

      'config_futur_type_uuid': {
        'config_futur_gamme_uuid': null,
        'config_futur_configuration_uuid': null,
        'config_futur_intitule_uuid': null,
        'config_futur_designation_uuid': null,
      },
      'config_futur_gamme_uuid': {
        'config_futur_configuration_uuid': null,
        'config_futur_intitule_uuid': null,
        'config_futur_designation_uuid': null,
      },
      'config_futur_configuration_uuid': {
        'config_futur_intitule_uuid': null,
        'config_futur_designation_uuid': null,
      },
      'config_futur_intitule_uuid': {
        'config_futur_designation_uuid': null,
      }
    }
    let set_nullable = nullables_values[name] && event.target.value !== this.state.mobilier[name] ? nullables_values[name] : {}

    this.setState({
      mobilier: {
        ...this.state.mobilier,
        [name]: event.target.value,
        ...set_nullable
      }
    }, () => {
      // Définition du code proginove si besoin.
      let fields = {
        config_futur_type_uuid: 'type_uuid',
        config_futur_gamme_uuid: 'gamme_uuid',
        config_futur_configuration_uuid: 'configuration_uuid',
        config_futur_intitule_uuid: 'intitule_uuid',
        config_futur_designation_uuid: 'designation_uuid',
      }
      // Vérifie que tous les champs futures sont complètés pour retrouver le code progi correspondant
      let all_fields_completed = Object.keys(fields).every(key => !!this.state.mobilier[key])

      if (all_fields_completed) {
        // On filtre les Code Proginove en fonction des type, gamme, configuration,initule et désignation
        let code_proginoves = this.props.code_proginovsStore.list.filter(code => {
          return Object.keys(fields).every(key => {
            let code_progi_field = code[fields[key]],
              mobilier_field = this.state.mobilier[key]
            return code_progi_field === mobilier_field
          })
        })
        let code_proginove = code_proginoves.length > 0 ? code_proginoves[0] : null

        this.setState({
          mobilier: {
            ...this.state.mobilier,
            general_immat_proginov: code_proginove ? code_proginove.code : null
          }
        })
      }
    });
  };

  onChangeHandlerGps(newValues) {
    this.setState({
      mobilier: {
        ...this.state.mobilier,
        ...newValues
      }
    })
  }

  /**
   * Gestion de la modification des champs "switch" on/off
   * @param name
   * @returns {Function}
   */
  onChangeSwitchHandler = name => event => {
    this.setState({
      mobilier: {
        ...this.state.mobilier,
        [name]: event.target.checked
      }
    });
  };

  /**
   * Handler de changement de schema
   * @param  {[type]} json [description]
   * @param  {[type]} blob [description]
   * @return {[type]}      [description]
   */
  onChangeSchema(json, blob) {
    if (!(this.state.mobilier.schema.json === json)) {
      // Volontairement sans setState pour ne pas faire de render inutiles
      /* eslint-disable-next-line */
      this.state.mobilier.schema = {
        ...this.state.mobilier.schema,
        json,
        blob
      }
      /* eslint-disable-next-line */
      this.state.schemaUpdated = true;
    }
  }

  /**
   * Handler de changement de photo
   * @param  {[type]} e [description]
   * @return {[type]}          [description]
   */
  onChangeFile(e) {
    let reader = new FileReader(),
      file = e.target.files[0],
      acceptedFiles = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
    if (acceptedFiles.indexOf(file.type) > -1) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        });
      };
      reader.readAsDataURL(file)
    }
  }

  /**
   * Gestion de la soumission du formulaire
   */
  onSubmitHandler() {
    const {dispatch, onCloseHandler} = this.props;
    let data = this.state.mobilier;
    if (!this.state.schemaUpdated) {
      delete data.scchema;
    }
    collectionActions(dispatch, 'mobiliers', 'UPDATE', {
      ...this.state.mobilier,
      date_debut: this.props.date_debut,
      date_fin: this.props.date_fin
    }, () => {
      this.setState({mobilier: null});
      onCloseHandler()
    });

    if (this.state.file) {
      let data = new FormData();
      data.append('file', this.state.file);

      updatePhoto(dispatch, this.state.mobilier.uuid, data, () => {
        this.setState({
          imagePreviewUrl: null,
          file: null
        })
      });
    }
  }

  getTabs() {
    const {classes} = this.props;
    let tabs = [];
    tabs.push(<Tab key="general" label={"Général"} title="Général" icon={<Computer/>}
                   className={classes.tab + ' ' + (this.getErrorClass('Général'))}/>)
    tabs.push(<Tab key="geoloc" label={"Géolocalisation"} title="Géolocalisation" icon={<LocationOn/>}
                   className={classes.tab + ' ' + (this.getErrorClass('Géolocalisation'))}/>)
    tabs.push(<Tab key="photo" label={"Photo"} title="Photo" icon={<PhotoCamera/>} className={classes.tab}/>)
    tabs.push(<Tab key="schema" label={"Schéma"} title="Schéma" icon={<Edit/>} className={classes.tab} onClick={e => {
      // Permet de remonter le scroll tout en haut quand on arrive sur le schéma
      let parentElement = document.getElementById('modalContainer')
      if (parentElement) parentElement.parentNode.scrollTop = 0;
    }}/>)

    if (!isTablet && !isMobile) {
      tabs.push(<Tab key="config" label={"Configurations"} title="Configurations" icon={<Settings/>}
                     className={classes.tab + ' ' + (this.getErrorClass('Configurations'))}/>)
      tabs.push(<Tab key="suivi" label={"Suivi"} title="Suivi" icon={<Schedule/>}
                     className={classes.tab + ' ' + (this.getErrorClass('Suivi'))}/>)
      tabs.push(<Tab key="options" label={"Options"} title="Options" icon={<BubbleChart/>}
                     className={classes.tab + ' ' + (this.getErrorClass('Options'))}/>)
    }
    return tabs
  }

  /**
   * Construit le menu de navigation
   * @returns {*}
   */
  getAppBarContent() {
    return (
      <Tabs
        value={this.state.tabValue}
        onChange={this.handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
      >
        {this.getTabs()}
      </Tabs>
    )
  }

  getErrorClass(tab_name) {
    const {classes, mobiliersStore} = this.props;
    let fields = [];
    switch (tab_name) {
      case 'Configurations':
        fields = [
          'config_existant_type_uuid',
          'config_existant_gamme_uuid',
          'config_existant_configuration_uuid',
          'config_existant_intitule_uuid',
          'config_existant_designation_uuid',

          'config_futur_type_uuid',
          'config_futur_gamme_uuid',
          'config_futur_configuration_uuid',
          'config_futur_intitule_uuid',
          'config_futur_designation_uuid',

          'config_existant_finition_uuid',
          'config_existant_depose_par',
          'config_existant_is_raccorde',
          'config_existant_is_a_recuperer',
          'config_futur_finition_uuid',
          'config_futur_ral',
          'config_futur_plan_recollement',
          'config_futur_pt_raccordement',
          'config_futur_face_ie',
          'config_futur_tranchee',
          'config_futur_a_raccorder',
          'suivi_observations_resp_technique',
          'suivi_observations_intervenant',
        ];
        break;
      case 'Géolocalisation':
        fields = [
          "geoloc_nom_arret",
          "geoloc_code_emplacement",
          "geoloc_stationnement",
          "geoloc_adresse",
          "geoloc_adresse_complement",
          "geoloc_code_postal",
          "geoloc_ville",
          "geoloc_latitude",
          "geoloc_longitude",
          "geoloc_azimut"
        ];
        break;
      case 'Général':
        fields = [
          'general_num_fiche',
          'general_num_bon_commande',
          'general_num_facturation',
          'general_immat_proginov',
          'general_type_travaux',
        ];
        break;
      case 'Suivi':
        fields = [
          'suivi_ent_depose',
          'suivi_date_depose',
          'suivi_ent_pose',
          'suivi_date_pose',
          'suivi_ent_scellement',
          'suivi_ent_finition',
          'suivi_ent_raccordement',
          'suivi_date_controle_ep',
          'suivi_date_enedis',
          'suivi_date_plan_recollement',
          'suivi_date_rea_raccordement',
          'suivi_date_reception',
          'suivi_date_reception_materiel',
          'suivi_date_rembrayage_refection',
          'suivi_date_terrassement_coulage',
        ];
        break;
      case 'Options':
        fields = [
          'options_banc',
          'options_corbeille',
          'options_cadre_horaire',
          'options_rampe_courtoisie',
          'options_bandeau_frontal',
          'options_bandeau_lateral',
          'options_autres',
        ];
        break;
      default:
        fields = []
    }

    return mobiliersStore.errors && Object.keys(mobiliersStore.errors).filter(key => fields.indexOf(key) > -1).length > 0 ? classes.tabError : null;
  }

  /**
   * Rendu global
   * @returns {*}
   */
  render() {
    const {classes, open, onCloseHandler, mobiliersStore} = this.props;
    let {imagePreviewUrl, mobilier, tabValue} = this.state;

    if (!this.state.mobilier || !this.state.mobilier.uuid) return null;

    return (
      <ModalFullScreen
        openModal={open}
        onCloseHandler={onCloseHandler}
        onSubmitHandler={this.onSubmitHandler.bind(this)}
        appBarContent={this.getAppBarContent()}
      >
        <Grid container id="modalContainer" className={classes.modalContainer}>
          <SwipeableViews
            axis={'x'}
            index={tabValue}
            onChangeIndex={this.handleChangeTabIndex}
            disabled={true}
          >
            <TabContainer>
              <UpdateConfigurations
                onChangeHandler={this.onChangeHandler.bind(this)}
                onChangeSwitchHandler={this.onChangeSwitchHandler.bind(this)}
                mobiliersStore={mobiliersStore}
                mobilier={mobilier}
              />
            </TabContainer>
            <TabContainer>
              <UpdateGeolocalisation
                onChangeHandlerGps={this.onChangeHandlerGps.bind(this)}
                onChangeHandler={this.onChangeHandler.bind(this)}
                mobiliersStore={mobiliersStore}
                mobilier={mobilier}
              />
            </TabContainer>
            <TabContainer>
              <UpdatePhoto
                onChangeHandler={this.onChangeFile.bind(this)}
                imagePreviewUrl={imagePreviewUrl}
                mobilier={mobilier}
              />
            </TabContainer>
            <TabContainer className={classes.updateSchema}>
              <UpdateSchema
                mobilier={mobilier}
                onChangeHandler={this.onChangeSchema.bind(this)}
              />
            </TabContainer>
            <TabContainer>
              <UpdateGeneral
                onChangeHandler={this.onChangeHandler.bind(this)}
                mobiliersStore={mobiliersStore}
                onChangeSwitchHandler={this.onChangeSwitchHandler.bind(this)}
                mobilier={mobilier}
              />
            </TabContainer>
            <TabContainer>
              <UpdateSuivi
                onChangeHandler={this.onChangeHandler.bind(this)}
                mobiliersStore={mobiliersStore}
                mobilier={mobilier}
              />
            </TabContainer>
            <TabContainer>
              <UpdateOptions
                onChangeHandler={this.onChangeHandler.bind(this)}
                mobiliersStore={mobiliersStore}
                mobilier={mobilier}
              />
            </TabContainer>
          </SwipeableViews>
        </Grid>
      </ModalFullScreen>
    );
  }
}

Update = connect((store) => {
  return {
    mobiliersStore: store.collections.mobiliers,
    code_proginovsStore: store.collections.code_proginovs
  }
})(Update);

Update.propTypes = {
  onCloseHandler: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  mobilier_uuid: PropTypes.string
};

Update = withStyles(MobilierCss)(Update);

export default Update
