import {Grid, withStyles, Fab, Button} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {NavigateBefore, NavigateNext, CloudDownload} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, {Component} from "react";
import {getSemainesOuvrees} from "./actions/HomeActions";
import SelecteurSemainesCss from "./css/SelecteurSemainesCss";
import {connect} from 'react-redux';

class SelecteurSemaines extends Component {
  /**
   * Gère le changement d'état du selecteur de semaine
   * @param event
   */
  handleChange = event => {
    this.setState({
      selectedWeekIndex: this.state.listeSemaines.findIndex(semaine => {
          return semaine.numero_semaine === event.target.value
        }
      )
    }, () => {
      this.props.callback(this.state.listeSemaines[this.state.selectedWeekIndex])
    });
  };

  componentDidMount() {
    let {dispatch} = this.props;
    getSemainesOuvrees(dispatch, (semainesOuvrees) => {
      this.setState({
        listeSemaines: semainesOuvrees,
        selectedWeekIndex: semainesOuvrees.findIndex(semaine => {
            return semaine.current_week
          }
        )
      }, () => {
        this.props.callback(this.state.listeSemaines[this.state.selectedWeekIndex])
      })
    });
  }

  /**
   * Génère la liste des semaines
   * @returns {Array}
   */
  getSelectItems() {
    const {classes} = this.props;

    let results = [],
      options = {year: 'numeric', month: 'numeric', day: 'numeric'};

    if (this.state) results.push(Object.keys(this.state.listeSemaines).map(key => {
      let semaine = this.state.listeSemaines[key],
        dateDebut = new Date(this.state.listeSemaines[key].date_debut),
        dateFin = new Date(this.state.listeSemaines[key].date_fin);

      return (
        <MenuItem
          key={semaine.numero_semaine + '_' + key}
          value={semaine.numero_semaine}
          name={'numero_semaine'}
          className={classes.menuItem}
        >
          <div className={classes.numeroSemaine}>{semaine.numero_semaine}</div>
          <div className={classes.datesContainer}>
            <div className={classes.dates}>
              {dateDebut.toLocaleDateString('fr-FR', options)} - {dateFin.toLocaleDateString('fr-FR', options)}
            </div>
            <div className={classes.nombreChantiers}>
              {semaine.nombre_chantiers + (semaine.nombre_chantiers > 1 ? ' chantiers' : ' chantier')}
            </div>
          </div>
        </MenuItem>
      )
    }));

    return results;
  }

  /**
   * Gère le click sur la flèche gauche
   */
  onClickBefore() {
    this.setState({
      selectedWeekIndex: this.state.selectedWeekIndex - 1
    }, () => {
      this.props.callback(this.state.listeSemaines[this.state.selectedWeekIndex])
    })
  }

  /**
   * Gère le click sur la flèche droite
   */
  onClickAfter() {
    this.setState({
      selectedWeekIndex: this.state.selectedWeekIndex + 1
    }, () => {
      this.props.callback(this.state.listeSemaines[this.state.selectedWeekIndex])
    })
  }

  /**
   * Rendu global
   * @returns {*}
   */
  render() {
    const {classes} = this.props;

    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container spacing={4} justify="center">
          <Fab
            color="primary"
            className={classes.button}
            onClick={this.onClickBefore.bind(this)}
            disabled={this.state ? this.state.selectedWeekIndex === 0 : true}
          >
            <NavigateBefore className={classes.icon}/>
          </Fab>
          <FormControl className={classes.formControl}>
            <Select
              value={this.state && this.state.listeSemaines && this.state.listeSemaines[this.state.selectedWeekIndex] ? this.state.listeSemaines[this.state.selectedWeekIndex].numero_semaine : ''}
              onChange={this.handleChange}
            >
              {this.getSelectItems()}
            </Select>
          </FormControl>
          <Fab
            color="primary"
            className={classes.button}
            onClick={this.onClickAfter.bind(this)}
            disabled={this.state ? this.state.listeSemaines.length - 1 === this.state.selectedWeekIndex : true}
          >
            <NavigateNext className={classes.icon}/>
          </Fab>
          <Button
            title='Télécharger le planning'
            color='primary'
            variant="contained"
            style={{position: 'absolute', right: 0, margin: '24px'}}
            onClick={() => {
              let options = this.state.listeSemaines[this.state.selectedWeekIndex];
              window.open(process.env.REACT_APP_API_URL + '/mobiliers/export/planning?date_debut='+options.date_debut+'&date_fin='+options.date_fin);
            }}
          >
            <CloudDownload style={{marginRight: '8px'}}/>Export
          </Button>
        </Grid>
      </Grid>
    )
  }
}

SelecteurSemaines = withStyles(SelecteurSemainesCss)(SelecteurSemaines);

SelecteurSemaines = connect((store) => {
  return {
  }
})(SelecteurSemaines);

SelecteurSemaines.propTypes = {
  callback: PropTypes.func.isRequired
};

export default SelecteurSemaines
