import {
  DialogContent, DialogTitle, Fade, Grid, IconButton, Fab
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {Add, Delete, Edit} from '@material-ui/icons';
import {Cell, DataTable} from '@oniti/datatable-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from "react-redux";
import {collectionActions, loadCollectionAttribute} from "../../../../reducers/collectionsReducer";
import {hasRights} from '../../Tools/Tools';
import Modal from '../Modal';
import CreateUpdate from './CreateUpdate';
import CollectionCrudCss from './css/CollectionCrudCss';

class CollectionCrud extends Component {

constructor(props) {
  super(props);
  this.state = {
    list: [],
    openModal: (!!props.modal) ? props.modal.open : false,
    openDeleteModal: false,
    uuidSelected: null,
    detail: null
  };

  if (!props.collectionsStore[props.collectionName]) throw new Error('Collection inconnue')
}

/**
 * Retourne le nouveau state en fonction des nextProps
 * @param  {[type]} nextProps [description]
 * @param  {[type]} prevState [description]
 * @return {[type]}           [description]
 */
  static getDerivedStateFromProps(nextProps, prevState) {
      // Met à jour l'état de la modale de création/modification
      if (nextProps.hasOwnProperty('modal') && !!nextProps.modal
          && (nextProps.modal.open !== prevState.openModal)) {
          return {openModal: nextProps.modal.open}
      }

      // Met à jour l'état de la collection
      const {collectionsStore, collectionName,datas} = nextProps
      const propsList = datas ? datas : collectionsStore[collectionName].list
      if (propsList !== prevState.list) {
          return {list: propsList}
      }
      return null
  }

/**
 * Lors du montage du composant
 */
  componentDidMount() {
      const {
          collectionsStore, collectionName, dispatch, rights, user, loadDatas
      } = this.props
      let readRight = true
      if(!!rights && !!rights.read){
          readRight = hasRights(rights.read,user)
      }
      let need_load_datas = this.props.hasOwnProperty('loadDatas') ? loadDatas : true
      if(need_load_datas && readRight && !Array.isArray(this.props.datas)){
          loadCollectionAttribute(
              dispatch, 'list', collectionName, collectionsStore[collectionName],
              null
          )
      }
  }

/**
 * Handler sur edit
 * @param  {[type]} detail [description]
 * @return {[type]}      [description]
 */
onClickEditHandler(detail) {
  this.setState({
    openModal: true,
    uuidSelected: detail.uuid
  });
  if (this.props.onModalOpen) {
    this.props.onModalOpen(detail);
  }
}

/**
 * Fermeture de la modal de delete
 * @return {[type]} [description]
 */
onCloseDeleteModalHandler() {
  this.setState({
    openDeleteModal: false,
    detail: null
  })
}

/**
 * Validation de l'action delete
 * @return {[type]} [description]
 */
onSubmitHandler() {
  const {dispatch, collectionName, actionsCallback} = this.props;
  collectionActions(dispatch, collectionName, 'DELETE', this.state.detail, () => {
    if (actionsCallback) actionsCallback('delete', this.state.detail)
  });
  this.setState({
    openDeleteModal: false,
    user: null
  })
}

/**
 * Clic sur le bouton Delete
 * @param  {[type]} detail [description]
 * @return {[type]}        [description]
 */
onClickDeleteHandler(detail) {
  this.setState({
    openDeleteModal: true,
    detail
  })
}

/**
 * Handler pour ajouter un detail de la collection
 */
addBtnCallBack() {
  this.setState({
    openModal: true,
    uuidSelected: null
  });
  if (this.props.onModalOpen) {
    this.props.onModalOpen({});
  }
}

/**
 * Handler sur la fermeture de la modal
 * @return {[type]} [description]
 */
onCloseModalHandler() {
  const {actionsCallback, collectionName, dispatch} = this.props;
  this.setState({
    openModal: false,
    uuidSelected: null
  });
  collectionActions(dispatch, collectionName, 'RESET_ERRORS')
  if (actionsCallback) actionsCallback('close', this.state.detail)
}

/**
 * Rendu de la cellule des actions
 * @param  {[type]} object [description]
 * @return {[type]}       [description]
 */
formatActions(object) {
  let {classes, showBtnEdit, showBtnDelete,rights,user,additionnalControllers, showBtnDeleteCallBack, showBtnEditCallBack} = this.props;

  if(!!rights && !!rights.edit && showBtnEdit){
      showBtnEdit = hasRights(rights.edit,user)
  }
  if(showBtnDelete && showBtnDeleteCallBack) showBtnDelete = showBtnDeleteCallBack(object)

  if(!!rights && !!rights.delete && showBtnDelete){
      showBtnDelete = hasRights(rights.delete,user)
  }
  if(showBtnEdit && showBtnEditCallBack) showBtnEdit = showBtnEditCallBack(object)

  additionnalControllers = additionnalControllers ? additionnalControllers : [];

  let btnEdit = (
      <IconButton
        aria-label="Edit"
        key="edit"
        color="primary"
        onClick={this.onClickEditHandler.bind(this, object)}
        className={classes.button}
        title="Modifier"
      >
        <Edit/>
      </IconButton>
    ),
    btnDelete = (
      <IconButton
        aria-label="Delete"
        onClick={this.onClickDeleteHandler.bind(this, object)}
        className={classes.button}
        key="delete"
        title="Supprimer"
      >
        <Delete/>
      </IconButton>
    );

  let result = [];

  additionnalControllers.forEach(f => result.push(f(object)));

  return [
    showBtnDelete ? btnDelete : null,
    showBtnEdit ? btnEdit : null,
  ].concat(result);
}

/**
 * Bouton d'ajout d'un utilisateur
 * @return {[type]} [description]
 */
getBtnAdd() {
  let {classes,rights,user} = this.props;
  let showBtn = true
  if(!!rights && !!rights.create){
      showBtn = hasRights(rights.create,user)
  }
  if(!showBtn) return false
  return (
      <Fab
        size="small"
        color="primary"
        aria-label="add"
        onClick={this.addBtnCallBack.bind(this)}
        className={classes.addButton}
        title="Ajouter"
      >
        <Add style={{fontSize: 32}}/>
      </Fab>
  );
}

/**
 * Retourne les Cells du datatable
 * @return {[type]} [description]
 */
getCells() {
    const {
        showBtnEdit, showBtnDelete, additionnalControllers, cellsConfig, classes
    } = this.props;
  let cells = cellsConfig.map((conf, index) => <Cell key={index} {...conf} />);
  if (showBtnEdit || showBtnDelete || additionnalControllers) cells.push(
    <Cell
      key="crud"
      format={this.formatActions.bind(this)}
      sortable={false}
      className={classes.crud_td}
    />
  );

  return cells;
}

//Save state on unmount Datatable
saveDatatableState(state){
  const { persistDatatableOptions } = this.props;
  if(!!persistDatatableOptions){
    localStorage.setItem(this.getStorageKeyDatatable(),JSON.stringify(state))
  }
}
//Restore state for datatable
getInitValue(){
  const { persistDatatableOptions } = this.props;
  let initValue = null

  if(!!persistDatatableOptions){
    let storagekey = this.getStorageKeyDatatable()
    if(localStorage[storagekey]){
      initValue = JSON.parse(localStorage[storagekey])
      let now = new Date().getTime(),
          expiration_time = !!persistDatatableOptions.expiration_minutes ?
                              (initValue.timestamp +(persistDatatableOptions.expiration_minutes * 60000)) :
                              null
      //Vérification de l'expiration
      if(!!persistDatatableOptions.expiration_minutes && now >= expiration_time ){
        localStorage.removeItem(storagekey)
        initValue = null
      }
    }
  }

  return initValue
}

/**
 * Rendu Final
 * @return {[type]} [description]
 */
render() {
  const {
    classes,
    datatableConfig,
    collectionName,
    showBtnAdd,
    showBtnEdit,
    showBtnDelete,
    deleteModalTitle,
    deleteModalContent,
    createUpdateModalTitle,
    createUpdateModalContent,
    createUpdateModalAction,
    extradatasForm,
    actionsCallback,
    dataTableExtraNodes,
    modalMaxWidth,
    modal,
    rights,
    user,
    disabledEnterModal,
    cancelUpdateCheck,
    defaultValues,
    detailStateCallback,
    beforeSubmitTransformeData
  } = this.props;

  let showDataTable = true
  if(!!rights && !!rights.read){
      showDataTable = hasRights(rights.read,user)
  }
  if(!showDataTable) return null

  let extraNodes = null;

  if(showBtnAdd) {
    extraNodes = [{
      element: this.getBtnAdd(),
      position: 'top-right'
    }]
  }

  if(!!dataTableExtraNodes) {
    if(extraNodes === null) extraNodes = dataTableExtraNodes;
    else extraNodes = extraNodes.concat(dataTableExtraNodes)
  }

  return (
    <Grid container>
      <Fade in={true}>
        <div className={classes.root}>
          {(!modal || modal.only !== true) ? <Grid container spacing={0}>
            <Grid item xs={12}>
              <DataTable
                datas={this.state.list ? this.state.list : []}
                {...datatableConfig}
                extraNodes={extraNodes}
                cancelUpdateCheck={cancelUpdateCheck}
                getStateOnUnmount={this.saveDatatableState.bind(this)}
                initialValues={this.getInitValue()}
              >
                {this.getCells()}
              </DataTable>
            </Grid>
          </Grid> : null}

          {(!modal && showBtnDelete) ? <Modal
            openModal={this.state.openDeleteModal}
            onCloseHandler={this.onCloseDeleteModalHandler.bind(this)}
            onSubmitHandler={this.onSubmitHandler.bind(this)}
            fullWidth={true}
            maxWidth='sm'
          >
            <DialogTitle key="title" id="alert-dialog-slide-title">
              {deleteModalTitle(this.state.detail)}
            </DialogTitle>
            <DialogContent>
              {deleteModalContent(this.state.detail)}
            </DialogContent>
          </Modal> : null}

          {showBtnAdd || showBtnEdit ? <CreateUpdate
            disabledEnterModal={disabledEnterModal}
            openModal={this.state.openModal}
            uuidSelected={this.state.uuidSelected}
            onCloseHandler={this.onCloseModalHandler.bind(this)}
            collectionName={collectionName}
            createUpdateModalTitle={createUpdateModalTitle}
            createUpdateModalContent={createUpdateModalContent}
            createUpdateModalAction={createUpdateModalAction}
            extradatasForm={extradatasForm}
            actionsCallback={actionsCallback}
            modalMaxWidth={modalMaxWidth}
            defaultValues={defaultValues}
            detailStateCallback={detailStateCallback}
            beforeSubmitTransformeData={beforeSubmitTransformeData}
          /> : null}

        </div>
      </Fade>
    </Grid>
  );
}
}

CollectionCrud = withStyles(CollectionCrudCss)(CollectionCrud);

CollectionCrud = connect((store) => {
return {
  collectionsStore: store.collections,
  user:store.auth.user
}
})(CollectionCrud);

CollectionCrud.propTypes = {
  // store qui est écouté, URL vers le back, ...
  collectionName: PropTypes.string.isRequired,

  // config du DataTable et ses cellules,
  // cf https://www.npmjs.com/package/@oniti/datatable-material
  cellsConfig: PropTypes.array.isRequired,
  datatableConfig: PropTypes.object.isRequired,

  // optionnel : en passant loadDatas à false, on injecte les données
  // à la main par la prop "datas" -> Pour que le loadDatas à false 
  // soit pris en compte, il faut déclarer "datas" avec un tableau vide.
  // Les insert/update/delete continuent à fonctionner
  // comme d'hab (voir Cap Eco, par exemple)
  // L'usage prévu est le filtrage des données affichées.
  datas: PropTypes.array,
  loadDatas: PropTypes.bool,

  // affichage ou non des boutons d'action
  showBtnEdit: PropTypes.bool,
  showBtnAdd: PropTypes.bool,
  showBtnDelete: PropTypes.bool,

  // callback pour un affichage ou non du bouton d'action à la ligne près
  // (reçoit l'objet de la ligne et renvoie un booléen)
  showBtnDeleteCallBack: PropTypes.func,
  showBtnEditCallBack: PropTypes.func,

  // gère l'affichage des boutons en fonction des droits de l'utilisateur
  // voir également le Controller associé (__construct)
  // rights={{
  //   create: 'admin-c-affaires',
  //   edit: 'admin-u-affaires',
  //   delete: 'admin-d-affaires'
  // }}
  rights: PropTypes.object,

  // callbacks des titres et contenus
  deleteModalTitle: PropTypes.func,
  deleteModalContent: PropTypes.func,
  createUpdateModalTitle: PropTypes.func,
  createUpdateModalContent: PropTypes.func,
  // titre du bouton de validation de la modale
  createUpdateModalAction: PropTypes.string,

  // données envoyées en plus lors d'un insert/update
  // {facture_uuid: this.state.facture.uuid}
  extradatasForm: PropTypes.object,

  // ajoute des boutons supplémentaires sur chaque ligne
  // [ this.getDuplicateButton.bind(this), this.getDetailButton.bind(this)]
  // la fonction reçoit l'objet de la ligne cliquée et doit renvoyer
  // un IconButton (ou truc du genre)
  additionnalControllers: PropTypes.array,

  // permet d'accrocher une fonction à une action (create/update/delete)
  // la fonction reçoit l'action et l'objet (cf Cap Eco)
  actionsCallback: PropTypes.func,

  // permet d'ajouter des éléments autour du CollectionCrud
  // dataTableExtraNodes={[
  //   {
  //     element: this.getCrudTitle(),
  //     position: 'top-left',
  //   }
  // ]}
  dataTableExtraNodes: PropTypes.arrayOf(PropTypes.object),

  // permet d'indiquer la taille souhaitée pour le Dialog :
  // 'xs', 'sm', 'md', 'lg', 'xl', false
  modalMaxWidth: PropTypes.string,

  // modal={{only: true, open: this.state.xxx}}
  // permet de n'avoir que la gestion de la modale de création et
  // pas la grille.
  modal: PropTypes.object,

  // désactive la touche "Enter" pour la validation de la modale
  disabledEnterModal: PropTypes.bool,

  // désactive le filtrage par défaut des propriétés (qui permet d'éviter
  // de faire trop de render). Exemple : ajout de checkboxes dans la grille
  cancelUpdateCheck: PropTypes.bool,

  // detailStateCallback : permet d'informer notre parent des changements
  // de state des champs (ex: checkbox qui change le disabled d'un input à coté)
  // (et permet également de modifier le state en modifiant l'argument !)
  // ex : handleDetailStateUpdate(newState) {} (cf ILMG)
  detailStateCallback: PropTypes.func,

  // callback appelé à l'ouverture de la modale create/update, reçoit l'objet
  // en arguement
  onModalOpen: PropTypes.func,

  // permet d'alimenter la modale de création avec des valeurs par défaut
  defaultValues: PropTypes.object,

  // fait persister l'état du composant (recherche, tris, numéro de page,
  // taille de la page, ...). Objet vide {} accepté.
  // {
  //  id: xxx (optionnel, prend le nom de la collection par défaut)
  //  expiration_minutes: xxx (optionnel, n'expire pas par défaut)
  // }
  persistDatatableOptions: PropTypes.object,

  // Est appeler avant l'appel au back Office
  // Permet de modifier les datas envoyés
  // Utilisation typique rajouter un fichier dans la requete
  beforeSubmitTransformeData:PropTypes.func
};

export default CollectionCrud
