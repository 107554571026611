export default theme => ({
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    marginTop: '-3px',
    minWidth: '7em',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  autocompleteBold: {
    fontWeight: 'bold',
    backgroundColor: 'transparent!important'
  },
  loader: {
    marginTop: 0,
  },
  autoSuggestContainer: {
    position: 'relative',
    width: '100%'
  }
});
