import {Card, CardContent, CircularProgress, Grid, FormControlLabel,Switch} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import TextInput from '../../common/Components/TextInput/index';
import MobilierCss from "./css/MobilierCss";

class UpdateGeneral extends Component {
  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {classes, mobiliersStore, onChangeHandler, mobilier,onChangeSwitchHandler} = this.props;

    if (!mobilier) return (
      <Grid item xs={12} style={{textAlign: 'center'}}>
        <CircularProgress/>
      </Grid>
    );

    return (
      <Grid item xs={12} lg={12}>
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextInput
                  id="general_num_fiche"
                  type="text"
                  label="N° Fiche"
                  value={mobilier.general_num_fiche}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='general_num_fiche'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="general_num_bon_commande"
                  type="text"
                  label="N° Bon de commande"
                  value={mobilier.general_num_bon_commande}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='general_num_bon_commande'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="general_num_facturation"
                  type="text"
                  label="N° Facturation"
                  value={mobilier.general_num_facturation}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='general_num_facturation'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <TextInput
                  id="general_immat_proginov"
                  type="text"
                  label="N° Proginov"
                  value={mobilier.general_immat_proginov}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='general_immat_proginov'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="general_type_travaux"
                  type="text"
                  label="Type de travaux"
                  value={mobilier.general_type_travaux}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='general_type_travaux'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="general_consignation_electrique"
                  type="text"
                  label="Consignation Électrique"
                  value={mobilier.general_consignation_electrique}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='general_consignation_electrique'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                  <FormControlLabel
                    label="Exploitable"
                    control={
                      <Switch
                        color='primary'
                        checked={mobilier.general_exploitable}
                        onChange={onChangeSwitchHandler('general_exploitable')}
                      />
                    }
                  />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }
}

UpdateGeneral = withStyles(MobilierCss)(UpdateGeneral);

UpdateGeneral = connect((store) => {
  return {}
})(UpdateGeneral);

UpdateGeneral.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  mobilier: PropTypes.object.isRequired
};

export default UpdateGeneral
