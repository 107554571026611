import React from 'react';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import { createBrowserHistory } from 'history'
import {Route} from 'react-router';
import {ConnectedRouter, routerMiddleware, routerReducer} from 'react-router-redux';
import DevTools from './DevTools';
import * as reducers from '../reducers';
import {routes} from './routes';

import App from '../components/common/App';
import Error404 from '../components/common/ErrorsPages/Error404';

const devMode = process.env.NODE_ENV === 'development';

const history = createBrowserHistory();

const middleware = applyMiddleware(routerMiddleware(history));

const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer
  }),
  devMode ? compose(middleware, DevTools.instrument()) : compose(middleware)
);

class MainRouter extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App>
            <Switch>
              {routes.map(r => <Route path={r.path} exact={r.exact} key={r.path} component={r.component}/>)}
              <Route component={Error404}/>
            </Switch>
            {devMode ? <DevTools/> : null}
          </App>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default MainRouter;
