import {Fab, Grid} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles/index";
import {CameraAlt} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import MobilierCss from "./css/MobilierCss";
import InsertPhoto from '@material-ui/icons/InsertPhoto';

const previewHeight = (window.innerHeight - 117) + 'px',
  previewWidth = (window.innerWidth - 48) + 'px';

class UpdatePhoto extends Component {
  /**
   * Apperçu de la zone photo
   * @return {[type]} [description]
   */
  getPreview() {
    const {classes} = this.props;

    if (!this.props.imagePreviewUrl && (!this.props.mobilier || !this.props.mobilier.photo_url)) {
      return <InsertPhoto className={classes.defaultBackgroundIcon}
                          style={{width: previewWidth, height: previewHeight}}/>
    } else {
      return <img style={{maxWidth: previewWidth, maxHeight: previewHeight}}
                  src={this.props.imagePreviewUrl ? this.props.imagePreviewUrl : this.props.mobilier.photo_url}
                  alt="Aperçu"/>
    }
  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {classes, onChangeHandler} = this.props;

    return (
      <Grid item xs={12} className={classes.photoContainer}>
        <input
          accept="image/*"
          className={classes.inputFile}
          capture="camera"
          type="file"
          ref={input => this.inputfile = input}
          onChange={onChangeHandler}
        />
        <Fab
          color="primary"
          aria-label="photo"
          title="Prendre une Photo"
          className={classes.addButton}
          onClick={() => {
            this.inputfile.click()
          }}
        >
          <CameraAlt className={classes.addButtonIcon}/>
        </Fab>
        {this.getPreview()}
      </Grid>
    );
  }
}

UpdatePhoto = withStyles(MobilierCss)(UpdatePhoto);

UpdatePhoto.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  mobilier: PropTypes.object.isRequired,
  imagePreviewUrl: PropTypes.string
};

export default UpdatePhoto
