export default theme => ({
  floatright: {
    float: 'right'
  },
  root: {
    flexGrow: 1
  },
  projetsTitle: {
    float: 'left',
    marginTop: '1.8em',
    marginRight: '1.5em',
    color: theme.palette.primary.main
  },
  returnButton: {
    float: 'right'
  },
  labelInput: {
    position: 'relative',
    width: '20em',
    marginRight: '1em'
  },
  button: {
    float: 'right'
  },
  marchesTitle: {
    color: theme.palette.primary.main,
    marginBottom: '1em'
  },
  marcheTitle: {
    color: theme.palette.primary.main,
    float: 'left',
    marginBottom: 0,
    padding: '8px 0'
  },
  addButton: {
    marginLeft: '2em',
    marginTop: '0.5em',
    marginRight: '0.5em',
    color:'#FFFFFF'
  },
  societeInput: {
    width: '20em',
    display: 'inline-flex',
    marginRight: '1em',
    verticalAlign: 'top'
  },
  suggestionContainer: {
    width: '100%',
    zIndex: 1,
    position: 'relative',
    minWidth: '7em',
    marginTop: '-3px',
  }
});
