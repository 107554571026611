import {forceRedirectLogin} from './authReducer';
import {themeComplement} from "../components/admin/AppAdmin/css/theme";

/**
 * ###########################################################################
 * ##########################  FUNCTIONS #####################################
 * ###########################################################################
 */

export function clearNotification(dispatch) {
  dispatch({type: 'RESET_NOTIFICATION'})
}

export function addNotification(dispatch, options) {
  options.key = new Date().getTime()
  dispatch({payload: options, type: 'ADD_NOTIFICATION'})
}

export function axiosErrorHandler(dispatch, error) {
  if (error.response) {
    if (error.response.status === 401) {
      forceRedirectLogin(dispatch);
      addNotification(dispatch, {
        message: "Votre session a expiré",
        bgColor: themeComplement.palette.notifications.error.color
      })
    }
    else if (error.response.status === 422 && error.response.data.errors){
        Object.keys(error.response.data.errors).map( key => {
            let errors = error.response.data.errors[key]
            errors.map( err => {
                addNotification(dispatch, {
                  message: err,
                  bgColor: themeComplement.palette.notifications.error.color
                })
                return false;
            })
            return false;
        })
    }
    else if (error.response.data && error.response.data.error) {
      addNotification(dispatch, {
        message: error.response.data.error,
        bgColor: themeComplement.palette.notifications.error.color
      })
    }else if(error.response.status === 500){
      addNotification(dispatch, {
        message: "Une erreur est survenue",
        bgColor: themeComplement.palette.notifications.error.color
      })
    }
  } else {
    addNotification(dispatch, {
      message: "Une erreur est survenue",
      bgColor: themeComplement.palette.notifications.error.color
    })
    console.log(error)
  }
}

/**
 * ###########################################################################
 * ##########################  REDUCER   #####################################
 * ###########################################################################
 */

let initialState = {
  message: null,
  bgColor: null,
  cancelCallback: null,
  key:null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_NOTIFICATION': {
      return {...state, ...action.payload}
    }
    case 'RESET_NOTIFICATION': {
      return {...initialState}
    }
    default:
      return state
  }
}
