import {Grid, Typography, MenuItem,Button,CircularProgress,List, ListItem,ListItemText, DialogTitle, DialogContent} from '@material-ui/core';
import {Settings} from '@material-ui/icons';
import {connect} from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import React, {Component} from 'react';
import CollectionCrud from '../../../common/Components/CollectionCrud';
import TextInput from '../../../common/Components/TextInput';
import {loadCollectionAttribute} from '../../../../reducers/collectionsReducer'
import {massUpdateCodeProginov} from '../actions/ParametresActions'
import Modal from '../../../common/Components/Modal';
import Error from '@material-ui/icons/Error';

class CodeProginov extends Component {
  state = {
    loading:false,
    showModalError:true
  }

  componentDidMount(){
    loadCollectionAttribute(this.props.dispatch, 'list', 'types', this.props.typesStore);
    loadCollectionAttribute(this.props.dispatch, 'list', 'gammes', this.props.gammesStore);
    loadCollectionAttribute(this.props.dispatch, 'list', 'configurations', this.props.configurationsStore);
    loadCollectionAttribute(this.props.dispatch, 'list', 'intitules', this.props.intitulesStore);
    loadCollectionAttribute(this.props.dispatch, 'list', 'designations', this.props.designationsStore);
  }

   /**
   * Génère les menus déroulants
   * @param itemStore
   * @returns {any[]}
   */
  getOptions(itemStore,actual_value,relations) {
    if(!itemStore) return []
    return Object.keys(itemStore.list).filter( key => {
      let item = itemStore.list[key]
      return this.isValid(item,actual_value,relations)
    })
    .map(key => {
      let item = itemStore.list[key]
      return (
        <MenuItem value={item.uuid} key={item.uuid}>
          {item.libelle}
        </MenuItem>
      );
    })
  }

  isValid(item,actual_value,relations){
    let is_old_value = item.uuid === actual_value,
        isValidRelation = is_old_value || this.isValidRelation(item,relations)

    return (!item.deleted_at || is_old_value) && (isValidRelation || is_old_value)
  }

  isValidRelation(item,relations){
    if(!item.relations) return true
    let is_valid = false
    item.relations.forEach( r => {
      if(Object.keys(relations).every( key => r[key] === relations[key])){
        is_valid = true
      }
    })
    return is_valid
  }

  /**
   * Title de la modal de suppression
   * @return {[type]} [description]
   */
  getDeleteModalTitle(code_proginov) {
    return <div><SettingsIcon/> <span>{'Suppression du code proginov : ' + (code_proginov ? code_proginov.code : '')}</span>
    </div>;
  }

  getDeleteModalContent() {
    return <Typography>{'Êtes vous sûr de vouloir supprimer ce code proginov ?'}</Typography>;
  }

  getCreateUpdateModalTitle(code_proginov) {
    return <div><SettingsIcon/>
      <span>{!!code_proginov ? 'Édition du code proginov : ' + code_proginov.code : 'Création d\'un nouveau code proginov'}</span>
    </div>;
  }

  onChangeHandlerRelations(onChangeHandler,code_proginov,name,e){
    let {value} = e.target
    let nullables_values = {
      'type_uuid': {
        'gamme_uuid':null,
        'configuration_uuid':null,
        'intitule_uuid':null,
        'designation_uuid':null,
      },
      'gamme_uuid':{
        'configuration_uuid':null,
        'intitule_uuid':null,
        'designation_uuid':null,
      },
      'configuration_uuid':{
        'intitule_uuid':null,
        'designation_uuid':null,
      },
      'intitule_uuid':{
        'designation_uuid':null,
      }
    }
    let set_nullable = nullables_values[name] &&  value !== code_proginov[name] ? nullables_values[name] : {}
    set_nullable[name] = value
    onChangeHandler(name,null,true,set_nullable)
  }

  /**
   * Retourne le formulaire
   * @returns {*}
   */
  getCreateUpdateModalContent(code_proginov, code_proginovsStore, onChangeHandler) {
    let {code,type_uuid,gamme_uuid,configuration_uuid,intitule_uuid,designation_uuid} = code_proginov;
    const { typesStore,gammesStore,configurationsStore, intitulesStore,designationsStore} = this.props;


    return (
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <TextInput
            select
            label="Type"
            value={type_uuid}
            margin="normal"
            collectionStore={code_proginovsStore}
            name='type_uuid'
            onChangeHandler={this.onChangeHandlerRelations.bind(this,onChangeHandler,code_proginov)}
            fullWidth
          >
            {this.getOptions(typesStore,type_uuid)}
          </TextInput>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            select
            label="Famille"
            value={gamme_uuid}
            margin="normal"
            collectionStore={code_proginovsStore}
            name='gamme_uuid'
            onChangeHandler={this.onChangeHandlerRelations.bind(this,onChangeHandler,code_proginov)}
            fullWidth
          >
            {this.getOptions(gammesStore,gamme_uuid,{
              type_uuid
            })}
          </TextInput>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            select
            label="Sous Famille"
            value={configuration_uuid}
            margin="normal"
            collectionStore={code_proginovsStore}
            name='configuration_uuid'
            onChangeHandler={this.onChangeHandlerRelations.bind(this,onChangeHandler,code_proginov)}
            fullWidth
          >
            {this.getOptions(configurationsStore,configuration_uuid,{
              type_uuid,
              gamme_uuid
            })}
          </TextInput>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            select
            label="Intitule"
            value={intitule_uuid}
            margin="normal"
            collectionStore={code_proginovsStore}
            name='intitule_uuid'
            onChangeHandler={this.onChangeHandlerRelations.bind(this,onChangeHandler,code_proginov)}
            fullWidth
          >
            {this.getOptions(intitulesStore,intitule_uuid,{
              type_uuid,
              gamme_uuid,
              configuration_uuid,
            })}
          </TextInput>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            select
            label="Désignation complémentaire"
            value={designation_uuid}
            margin="normal"
            collectionStore={code_proginovsStore}
            name='designation_uuid'
            onChangeHandler={this.onChangeHandlerRelations.bind(this,onChangeHandler,code_proginov)}
            fullWidth
          >
            {this.getOptions(designationsStore,designation_uuid,{
              type_uuid,
              gamme_uuid,
              configuration_uuid,
              intitule_uuid
            })}
          </TextInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="code"
            label="Code"
            value={code}
            margin="normal"
            collectionStore={code_proginovsStore}
            name='code'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  onChangeUpdateCodeProginov(e){
    let file = e.target.files[0],
        data = new FormData();
    data.append("file",file);
    this.setState({loading:true},()=>{
      massUpdateCodeProginov(this.props.dispatch,data,()=>{
        this.setState({loading:false})
      })
    })
  }
  getInputImportCodes(){
    return (
      <form style={{display:'inline-block'}}>
        <Button
          title='Mise à jour des mobiliers'
          color='primary'
          variant="contained"
          onClick={()=>{
            document.getElementById('file_moblier_update').click()
          }}
          style={{marginBottom: '1em'}}
        >
          <Settings style={{marginRight: '8px'}}/> Mise à jour des codes
        </Button>
        <input 
          type='file' 
          style={{display:'none'}} 
          id='file_moblier_update'
          accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={this.onChangeUpdateCodeProginov.bind(this)}
        ></input>
      </form>
    )
  }

  

  /**
   * retourne la configuration des cellules
   * @return {[type]} [description]
   */
  getCellsConfig() {
    return [
      {
        datakey: "code",
        title: "Code Article",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "type.libelle",
        title: "Type",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "gamme.libelle",
        title: "Famille",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "configuration.libelle",
        title: "Sous Famille",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "intitule.libelle",
        title: "Intitulé",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "designation.libelle",
        title: "Désignation complémentaire",
        sortable: true,
        searchable: true,
      },
    ]
  }

  onCloseErrorModals(){
    this.setState({
      showModalError:false
    })
    this.props.dispatch({
      type:'RESET_ERRORS',
      collection: 'code_proginovs'
    })
  }

  getErrors(){
    const {code_proginov_errors} = this.props
    if(!code_proginov_errors || code_proginov_errors.length === 0) return null

    const {showModalError} = this.state

    return (
      <Modal
        openModal={showModalError}
        onCloseHandler={this.onCloseErrorModals.bind(this)}
        onSubmitHandler={this.onCloseErrorModals.bind(this)}
        fullWidth={true}
        maxWidth='lg'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          <Error/> <span>Liste de erreurs lors de l'import</span>
        </DialogTitle>
        <DialogContent>
          <Grid container>
          <List>
          </List>
            {code_proginov_errors.map((err,index) => {
              return (
                <ListItem key={index}>
                  <ListItemText
                    primary={err.message}
                  />
                </ListItem>
              )
            })}
          </Grid>
        </DialogContent>
      </Modal>
    )
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    if (this.state.loading) return <Grid style={{textAlign:'center'}}><CircularProgress/></Grid>;
    return (
      <>
        <CollectionCrud
          collectionName={'code_proginovs'}
          showBtnEdit={true}
          showBtnAdd={false}
          showBtnDelete={true}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "code",
            rowsPerPageOptions:[50, 25, 10]
          }}
          cellsConfig={this.getCellsConfig()}
          modalMaxWidth={"md"}
          dataTableExtraNodes={[
            {
              element: this.getInputImportCodes(),
              position: 'top-left',
            }
          ]}
        />
        {this.getErrors()}
      </>
    );
  }
}
CodeProginov = connect((store) => {
  return {
    typesStore: store.collections.types,
    gammesStore: store.collections.gammes,
    configurationsStore: store.collections.configurations,
    intitulesStore: store.collections.intitules,
    designationsStore: store.collections.designations,
    code_proginov_errors: store.collections.code_proginovs.errors
  }
})(CodeProginov);

export default CodeProginov
