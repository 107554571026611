import {DialogContent, DialogTitle, Grid, MenuItem,Button, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import MobilierIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {collectionActions, loadCollectionAttribute} from "../../../reducers/collectionsReducer";
import Modal from '../../common/Components/Modal';
import TextInput from '../../common/Components/TextInput';
import {createMobiliers} from './actions/MobilierActions';
import MobilierCss from './css/MobilierCss';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';

class CreateModal extends Component {
  state = {
    config_futur_type_uuid:null,
    config_futur_gamme_uuid:null,
    config_futur_configuration_uuid:null,
    config_futur_intitule_uuid:null,
    config_futur_designation_uuid:null,
    options_banc:0,
    options_corbeille:0,
    options_cadre_horaire:0,
    options_rampe_courtoisie:0,
    options_bandeau_frontal:0,
    options_bandeau_lateral:0,
    nb: "1"
  };

  componentDidMount(){
    loadCollectionAttribute(this.props.dispatch, 'list', 'types', this.props.typesStore);
    loadCollectionAttribute(this.props.dispatch, 'list', 'gammes', this.props.gammesStore);
    loadCollectionAttribute(this.props.dispatch, 'list', 'configurations', this.props.configurationsStore);
    loadCollectionAttribute(this.props.dispatch, 'list', 'intitules', this.props.intitulesStore);
    loadCollectionAttribute(this.props.dispatch, 'list', 'designations', this.props.designationsStore);
  }

   /**
   * Génère les menus déroulants
   * @param itemStore
   * @returns {any[]}
   */
  getOptions(itemStore,actual_value,relations) {
    if(!itemStore || !itemStore.list) return []
    return Object.keys(itemStore.list).filter( key => {
      let item = itemStore.list[key]
      return this.isValid(item,actual_value,relations)
    })
    .map(key => {
      let item = itemStore.list[key]
      return (
        <MenuItem value={item.uuid} key={item.uuid}>
          {item.libelle}
        </MenuItem>
      );
    })
  }

  isValid(item,actual_value,relations){
    let is_old_value = item.uuid === actual_value,
        isValidRelation = is_old_value || this.isValidRelation(item,relations)

    return (!item.deleted_at || is_old_value) && (isValidRelation || is_old_value)
  }

  isValidRelation(item,relations){
    if(!item.relations) return true
    let is_valid = false
    item.relations.forEach( r => {
      if(Object.keys(relations).every( key => r[key] === relations[key])){
        is_valid = true
      }
    })
    return is_valid
  }

  /**
   * Soumission du formulaire
   * @return {[type]} [description]
   */
  onSubmitHandler() {
    const {dispatch, projet} = this.props;
    createMobiliers(dispatch, {
      projet_uuid: projet.uuid,
      ...this.state
    }, () => {
      collectionActions(dispatch, 'mobiliers', 'INDEX', {params: {projet_uuid: this.props.projet.uuid}});
      this.onCloseHandler()
    });
  }

  /**
   * Changement des inputs
   * @param  {[type]} name [description]
   * @param  {[type]} e    [description]
   * @return {[type]}      [description]
   */
  onChangeHandler(name, e) {
    let {value} = e.target;
    if (name === 'nb' && value < 0) value = "0";

    let nullables_values = {
      'config_futur_type_uuid': {
        'config_futur_gamme_uuid':null,
        'config_futur_configuration_uuid':null,
        'config_futur_intitule_uuid':null,
        'config_futur_designation_uuid':null,
      },
      'config_futur_gamme_uuid':{
        'config_futur_configuration_uuid':null,
        'config_futur_intitule_uuid':null,
        'config_futur_designation_uuid':null,
      },
      'config_futur_configuration_uuid':{
        'config_futur_intitule_uuid':null,
        'config_futur_designation_uuid':null,
      },
      'config_futur_intitule_uuid':{
        'config_futur_designation_uuid':null,
      }
    }
    let set_nullable = nullables_values[name] &&  value !== this.state[name] ? nullables_values[name] : {}

    this.setState({
      [name]: value,
      ...set_nullable
    })
  }


  /**
   * Fermeture de la modal
   * @return {[type]} [description]
   */
  onCloseHandler() {
    const {onCloseHandler, dispatch} = this.props;
    this.setState({
      config_futur_type_uuid:null,
      config_futur_gamme_uuid:null,
      config_futur_configuration_uuid:null,
      config_futur_intitule_uuid:null,
      config_futur_designation_uuid:null,
      options_banc:0,
      options_corbeille:0,
      options_cadre_horaire:0,
      options_rampe_courtoisie:0,
      options_bandeau_frontal:0,
      options_bandeau_lateral:0,
      nb: "1"
    });
    collectionActions(dispatch, 'mobiliers', 'RESET_ERRORS');
    onCloseHandler()
  }

  onClickHandler(action, value, name) {
    this.onChangeHandler(name, {
      target: {
        name,
        value: action === 'minus' ? (value > 0 ? value - 1 : 0) : value + 1
      }
    })
  }

  getOptionsMobilier() {
    const {classes, mobiliersStore} = this.props,
      options = [
        {
          libelle: 'Banc',
          colonne: 'options_banc',
        },
        {
          libelle: 'Corbeille',
          colonne: 'options_corbeille',
        },
        {
          libelle: 'Cadre horaire',
          colonne: 'options_cadre_horaire',
        },
        {
          libelle: 'Rampe courtoisie',
          colonne: 'options_rampe_courtoisie',
        },
        {
          libelle: 'Bandeau frontal',
          colonne: 'options_bandeau_frontal',
        },
        {
          libelle: 'Bandeau lateral',
          colonne: 'options_bandeau_lateral',
        }
      ];
    let result = [];

    result.push(
      options.map( option => {
        return (
          <Grid item xs={4} key={option.colonne}>
            <Grid container spacing={4}>
              <Grid item xs={5}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.optionsLibelle}
                >
                  {option.libelle}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Button
                  className={classes.optionsButton}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={this.onClickHandler.bind(this, 'minus', this.state[option.colonne], option.colonne)}
                >
                  <Remove/>
                </Button>
              </Grid>
              <Grid item xs={2}>
                <TextInput
                  id={option.colonne}
                  type="text"
                  inputProps={{style: {textAlign: 'center'}}}
                  className={classes.optionsInput}
                  value={this.state[option.colonne]}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name={option.colonne}
                  onChangeHandler={this.onChangeHandler.bind(this)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  className={classes.optionsButton}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={this.onClickHandler.bind(this, 'plus', this.state[option.colonne], option.colonne)}
                >
                  <Add/>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )
      })
    );

    return result
  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {
        open, 
        mobiliersStore,
        typesStore,
        gammesStore,
        configurationsStore,
        intitulesStore,
        designationsStore,
        classes
      } = this.props;
    let { nb, 
          config_futur_type_uuid,
          config_futur_gamme_uuid,
          config_futur_configuration_uuid,
          config_futur_intitule_uuid,
          config_futur_designation_uuid
        } = this.state;
    return (
      <Modal
        openModal={open}
        onCloseHandler={this.onCloseHandler.bind(this)}
        onSubmitHandler={this.onSubmitHandler.bind(this)}
        fullWidth={true}
        maxWidth='md'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          <MobilierIcon/><span>Ajout de nouveaux mobiliers</span>
        </DialogTitle>
        <DialogContent key="content">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextInput
                id="nombre"
                type="number"
                label="Nombre"
                inputProps={{min: "1"}}
                value={nb}
                margin="normal"
                collectionStore={mobiliersStore}
                name='nb'
                onChangeHandler={this.onChangeHandler.bind(this)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="button"
                gutterBottom
                className={classes.title}
              >
                Classification
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextInput
                select
                label="Type"
                value={config_futur_type_uuid}
                margin="normal"
                collectionStore={mobiliersStore}
                name='config_futur_type_uuid'
                onChangeHandler={this.onChangeHandler.bind(this)}
                fullWidth
              >
                {this.getOptions(typesStore,config_futur_type_uuid)}
              </TextInput>
            </Grid>
            <Grid item xs={4}>
              <TextInput
                select
                label="Famille"
                value={config_futur_gamme_uuid}
                margin="normal"
                collectionStore={mobiliersStore}
                name='config_futur_gamme_uuid'
                onChangeHandler={this.onChangeHandler.bind(this)}
                fullWidth
              >
                {this.getOptions(gammesStore,config_futur_gamme_uuid,{
                  type_uuid:config_futur_type_uuid
                })}
              </TextInput>
            </Grid>
            <Grid item xs={4}>
              <TextInput
                select
                label="Sous Famille"
                value={config_futur_configuration_uuid}
                margin="normal"
                collectionStore={mobiliersStore}
                name='config_futur_configuration_uuid'
                onChangeHandler={this.onChangeHandler.bind(this)}
                fullWidth
              >
                {this.getOptions(configurationsStore,config_futur_configuration_uuid,{
                  type_uuid:config_futur_type_uuid,
                  gamme_uuid:config_futur_gamme_uuid
                })}
              </TextInput>
            </Grid>
            <Grid item xs={4}>
              <TextInput
                select
                label="Intitule"
                value={config_futur_intitule_uuid}
                margin="normal"
                collectionStore={mobiliersStore}
                name='config_futur_intitule_uuid'
                onChangeHandler={this.onChangeHandler.bind(this)}
                fullWidth
              >
                {this.getOptions(intitulesStore,config_futur_intitule_uuid,{
                  type_uuid:config_futur_type_uuid,
                  gamme_uuid:config_futur_gamme_uuid,
                  configuration_uuid:config_futur_configuration_uuid,
                })}
              </TextInput>
            </Grid>
            <Grid item xs={4}>
              <TextInput
                select
                label="Désignation complémentaire"
                value={config_futur_designation_uuid}
                margin="normal"
                collectionStore={mobiliersStore}
                name='config_futur_designation_uuid'
                onChangeHandler={this.onChangeHandler.bind(this)}
                fullWidth
              >
                {this.getOptions(designationsStore,config_futur_designation_uuid,{
                  type_uuid:config_futur_type_uuid,
                  gamme_uuid:config_futur_gamme_uuid,
                  configuration_uuid:config_futur_configuration_uuid,
                  intitule_uuid:config_futur_intitule_uuid
                })}
              </TextInput>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="button"
                gutterBottom
                className={classes.title}
              >
                Options
              </Typography>
            </Grid>
            {this.getOptionsMobilier()}
          </Grid>
        </DialogContent>
      </Modal>
    );
  }
}

CreateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  projet: PropTypes.object
};

CreateModal = connect((store) => {
  return {
    projetsStore: store.collections.projets,
    mobiliersStore: store.collections.mobiliers,
    typesStore: store.collections.types,
    gammesStore: store.collections.gammes,
    configurationsStore: store.collections.configurations,
    intitulesStore: store.collections.intitules,
    designationsStore: store.collections.designations
  }
})(CreateModal);

CreateModal = withStyles(MobilierCss)(CreateModal);

export default CreateModal
