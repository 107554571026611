import {AppBar, Grid, Tab, Tabs, Typography} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import SwipeableViews from 'react-swipeable-views';
import Finition from "./Finition";
import Type from "./Type";
import CodeProginov from "./CodeProginov";
import SchemaImages from './SchemaImages'
import ParametresCss from "./css/ParametresCss";
import Societe from "./Societe";

function TabContainer({children, dir}) {
  return (
    <Typography component="div" dir={dir} style={{padding: 8 * 3}}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class Parametres extends Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({value});
  };

  handleChangeIndex = index => {
    this.setState({value: index});
  };

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    return (
      <Grid item xs={12}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            ariant="fullWidth"
          >
            <Tab label="Types" classes={this.props.classes}/>
            <Tab label="Code Article Proginov" classes={this.props.classes}/>
            <Tab label="Finitions" classes={this.props.classes}/>
            <Tab label="Images Schéma" classes={this.props.classes}/>
            <Tab label="Sociétés" classes={this.props.classes}/>
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
          <TabContainer dir={'rtl'}>
            <Type/>
          </TabContainer>
          <TabContainer dir={'rtl'}>
            <CodeProginov/>
          </TabContainer>
          <TabContainer dir={'rtl'}>
            <Finition/>
          </TabContainer>
          <TabContainer dir={'rtl'}>
            <SchemaImages/>
          </TabContainer>
          <TabContainer dir={'rtl'}>
            <Societe/>
          </TabContainer>
        </SwipeableViews>
      </Grid>
    );
  }
}

Parametres = withStyles(ParametresCss)(Parametres);

export default Parametres
