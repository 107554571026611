import {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getUser} from '../../../reducers/authReducer';
import {addPathRoute} from './reducers/appReducer'


class App extends Component {
  state = {
    previousRoutePath: null
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.previousRoutePath !== nextProps.location.pathname) {
      addPathRoute(nextProps.dispatch, nextProps.location.pathname);
      return {
        ...prevState,
        previousRoutePath: nextProps.location.pathname
      };
    }
    return null;
  }

  /**
   * Remplace componentWillMount
   * @return {[type]} [description]
   */
  componentDidMount() {
    getUser(this.props.dispatch)
  }

  /**
   * Fonction de rendu final du composant
   */
  render() {
    return this.props.children;
  }
}

App = connect((store) => {
  return {
    location: store.router.location,
    currentRouteConfig: store.app.currentRouteConfig
  }
})(App);

App = withRouter(App);

export default App
