import {
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  withStyles
} from '@material-ui/core';
import CollectionsBookmark from '@material-ui/icons/CollectionsBookmark';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {collectionActions} from "../../../reducers/collectionsReducer";
import Modal from '../../common/Components/Modal';
import TextInput from '../../common/Components/TextInput';
import MarcheCss from "./css/MarcheCss";

class CreateProjet extends Component {
  state = {
    projet: {
      contact: '',
      libelle: '',
      type: 'mep_initiale'
    }
  };

  /**
   * On Change Handler
   * @param  {[type]} name [description]
   * @param  {[type]} e    [description]
   * @return {[type]}      [description]
   */
  onChangeHandler(name, e) {
    this.setState({
      projet: {
        ...this.state.projet,
        [name]: e.target.value
      }
    })
  }

  /**
   * Fermeture de la modal
   * @return {[type]} [description]
   */
  onCloseHandler() {
    const {onCloseHandler, dispatch} = this.props;
    collectionActions(dispatch, 'projets', 'RESET_ERRORS');
    onCloseHandler()
  }

  /**
   * Soumission du formulaire
   * @return {[type]} [description]
   */
  onSubmitHandler() {
    const {dispatch, marche} = this.props;
    let data = {
      ...this.state.projet,
      marche_uuid: marche.uuid
    };
    collectionActions(dispatch, 'projets', 'CREATE', data, () => {
      this.onCloseHandler()
    });
  }

  changeTypeHandler = event => {
    this.setState({
      projet: {
        ...this.state.projet,
        type: event.target.value
      }
    })
  };

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {open, projetsStore} = this.props;
    return (
      <Modal
        openModal={open}
        onCloseHandler={this.onCloseHandler.bind(this)}
        onSubmitHandler={this.onSubmitHandler.bind(this)}
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          <CollectionsBookmark/><span>Ajout d'un projet</span>
        </DialogTitle>
        <DialogContent key="content">
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextInput
                label="Libellé"
                value={this.state.projet.libelle}
                margin="normal"
                collectionStore={projetsStore}
                name='libelle'
                onChangeHandler={this.onChangeHandler.bind(this)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label="Contact"
                value={this.state.projet.contact}
                margin="normal"
                collectionStore={projetsStore}
                name='contact'
                onChangeHandler={this.onChangeHandler.bind(this)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="type-helper">Type</InputLabel>
                <Select
                  value={this.state.projet.type}
                  onChange={this.changeTypeHandler}
                >
                  <MenuItem value='mep_initiale'>Mise en place initiale</MenuItem>
                  <MenuItem value='exploitation'>Exploitation</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
      </Modal>
    );
  }
}

CreateProjet = withStyles(MarcheCss)(CreateProjet);

CreateProjet = connect((store) => {
  return {
    projetsStore: store.collections.projets,
    marche: store.collections.marches.detail,
  }
})(CreateProjet);

export default CreateProjet
