import {createMuiTheme} from '@material-ui/core/styles';
import {assetsForCss} from '../../../tools/Tools';

const primaryColor = '#1fd0e5',
  terceraryColor = '#263238',
  textColorLight = '#b8c7ce',
  successColorMain = '#19b698',
  errorColorMain = '#ea6153';

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: primaryColor
    },
    error: {
      main: errorColorMain
    }
  },
  overrides: {
    MuiDrawer: {
      docked: {
        minHeight: '100vh'
      }
    },
    MuiDialogTitle: {
      root: {
        padding: '10px 15px',
        position: 'relative',
        backgroundColor: terceraryColor,
        '& h2': {
          color: 'white',
          '& svg': {
            width: '1.4em',
            height: '1.4em'
          },
          '& span': {
            position: 'absolute',
            top: '16px',
            left: '60px'
          }
        },
      }
    },
    MuiDialogContent: {
      root: {
        padding: '24px'
      }
    },
    MuiCircularProgress: {
      root: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '2em'
      }
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '2px solid ' + primaryColor
        },
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: '2px solid ' + primaryColor
        }
      }
    },
    textField: {
      focusColor: primaryColor
    },
    MuiButton: {
      containedPrimary: {
        color: 'white'
      }
    },
    MuiTableCell: {
      root: {
        padding: '0 .5em'
      },
      head: {
        fontSize: '.75rem',
        color: 'rgba(0, 0, 0, 0.54)'
      }
    },
  }
});

export const themeComplement = {
  palette: {
    leftBarDefaultWidth: 240,
    leftBarCollapsedWidth: 72,
    tercerary: terceraryColor,
    textColorLight: textColorLight,
    backgroundPath: assetsForCss('/img/default-background.jpg'),
    appBar: {
      color: '#b7b7b7'
    },
    notifications: {
      success: {
        color: successColorMain
      },
      error: {
        color: errorColorMain
      }
    }
  }
};
