import {CircularProgress, DialogContent, DialogTitle} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {collectionActions, loadCollectionAttribute} from "../../../../reducers/collectionsReducer";
import Modal from '../Modal';

class CreateUpdate extends Component {
  state = {
    detail: (this.props.defaultValues ? this.props.defaultValues : {}),
    deltailProps: null,
    prevProps: null
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
    static getDerivedStateFromProps(nextProps, prevState) {
        const {collectionsStore, collectionName} = nextProps
        let propsFollowed = ['openModal', 'uuidSelected', 'extradatasForm', 'defaultValues'],
            collectionNameStore = collectionsStore[collectionName],
            prevProps = {detail: collectionNameStore.detail}
        Object.keys(nextProps)
            .filter(k => propsFollowed.includes(k))
            .forEach(k => prevProps[k] = nextProps[k])
        let newState = {...prevState, prevProps: prevProps}
        loadCollectionAttribute(
            nextProps.dispatch,
            'detail',
            collectionName,
            collectionNameStore,
            {uuid: nextProps.uuidSelected}
        )
        if (JSON.stringify(collectionNameStore.detail)
            !== JSON.stringify(prevState.deltailProps)) {
            newState = {
                ...newState,
                detail: collectionNameStore.detail
                    ? collectionNameStore.detail
                    : {},
                deltailProps: collectionNameStore.detail
            };
        }

        if(!nextProps.uuidSelected && JSON.stringify(prevState.detail) === JSON.stringify({}) && !!nextProps.defaultValues){
            newState = {
              ...newState,
              detail: nextProps.defaultValues,
            };
        }

        // Ne pas mettre a jour le state par ce biais si les props n'ont pas changé
        // Cette méthode est appelée suite a l'update du state également
        if (prevState.prevProps === newState.prevProps) {
            newState = null
        }  else {
          if (nextProps.detailStateCallback) {
            nextProps.detailStateCallback(newState.detail);
          }
        }
        return newState
    }

  /**
   * Handler du changement sur les inputs
   * @param name
   * @param event
   */
  onChangeHandler(name, event,multipleUpdate = false, data = {}) {
    console.log(name, event)
    if(multipleUpdate){
      this.setState({
        detail: {
          ...this.state.detail,
          ...data
        }
      });
    }else{
      this.setState({
        detail: {
          ...this.state.detail,
          [name]: event.target.value
        }
      });
    }
    
  }

  /**
   * Handler sur la modification
   * @return {[type]} [description]
   */
  onSubmitHandler() {
    const {extradatasForm, collectionName, dispatch, actionsCallback, beforeSubmitTransformeData} = this.props;
    let data = this.state.detail;
    if (!!extradatasForm) {
      data = {...data, ...extradatasForm}
    }
    if(!!beforeSubmitTransformeData){
      data = beforeSubmitTransformeData(data)
    }
    let data_uuid = data ? data instanceof FormData ? data.get('uuid') : data.uuid :null;
    collectionActions(dispatch, collectionName, !!data_uuid ? 'UPDATE' : 'CREATE', data, (detail) => {
      this.props.onCloseHandler();
      if (actionsCallback) actionsCallback(!!data_uuid ? 'update' : 'create', detail)
    })
  }

  /**
   * Permet d'initialiser le state par défaut en cas de valeur préchargés
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  initialize(data) {
    // eslint-disable-next-line
    this.state.detail = {...this.state.detail, ...data}
  }

  /**
   * retourne le formulaire ou un loader
   * @return {[type]} [description]
   */
  getFormOrLoader() {
    const {collectionsStore, collectionName, createUpdateModalContent} = this.props;
    let collectionNameStore = collectionsStore[collectionName];
    let {detail} = collectionNameStore;
    if ((!detail && collectionNameStore.fetching) || !this.state.detail) return <CircularProgress
      className={this.props.classes.progress}/>;
    else {
      return createUpdateModalContent(this.state.detail, collectionNameStore, this.onChangeHandler.bind(this), this.initialize.bind(this));
    }
  }

  /**
   * Retourne le contenu de la modal
   * @return {[type]} [description]
   */
  getModalContent() {
    const {collectionsStore, collectionName, createUpdateModalTitle} = this.props;

    let collectionNameStore = collectionsStore[collectionName];
    let {detail} = collectionNameStore;

    return [
      <DialogTitle key="title" id="alert-dialog-slide-title">
        {createUpdateModalTitle(detail)}
      </DialogTitle>,
      <DialogContent key="content">
        {this.getFormOrLoader()}
      </DialogContent>
    ];


  }

    /**
     * Rendu Final
     * @return {[type]} [description]
     */
    render() {
        const {
            createUpdateModalAction,
            modalMaxWidth,
            onCloseHandler,
            openModal,
            disabledEnterModal,
        } = this.props
        if(!openModal) return null;
        return (
            <Modal
                actionOk={createUpdateModalAction}
                openModal={openModal}
                onCloseHandler={onCloseHandler}
                onSubmitHandler={this.onSubmitHandler.bind(this)}
                fullWidth={true}
                maxWidth={!!modalMaxWidth ? modalMaxWidth : 'sm'}
                disabledEnter={disabledEnterModal}
            >
                {this.getModalContent()}
            </Modal>
      )
  }
}


CreateUpdate.propTypes = {
  openModal: PropTypes.bool,
  uuidSelected: PropTypes.string,
  onCloseHandler: PropTypes.func.isRequired,
  collectionName: PropTypes.string.isRequired,
  createUpdateModalTitle: PropTypes.func,
  createUpdateModalContent: PropTypes.func,
  createUpdateModalAction: PropTypes.string,
  extradatasForm: PropTypes.object,
  actionsCallback: PropTypes.func,
  modalMaxWidth: PropTypes.string,
  detailStateCallback: PropTypes.func,
  disabledEnterModal: PropTypes.bool,
  defaultValues: PropTypes.object,
  beforeSubmitTransformeData:PropTypes.func
};

CreateUpdate = connect((store) => {
  return {
    collectionsStore: store.collections
  }
})(CreateUpdate);

export default withStyles(theme => ({}))(CreateUpdate)
