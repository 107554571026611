export default theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    float: 'right'
  },
  addButton: {
    marginLeft: '2em',
    marginTop: '0.5em',
    marginRight: '0.5em',
    color:'#FFFFFF'
  },
  addButtonIcon: {
    fontSize: '32px'
  },
  error: {
    color: theme.palette.error.main
  },
  divider: {
    marginTop: '1em',
    marginBottom: '1em'
  },
  floatright: {
    float: 'right'
  },
  labelInput: {
    position: 'relative',
    marginTop: 0,
    marginBottom: '20px',
    width: '17em',
    marginRight: '1em'
  },
  validButton: {
    height: 40,
    marginRight: theme.spacing(1)
  },
  exportPiquetageButton: {
    marginRight: theme.spacing(1)
  },
  returnButton: {
    float: 'right',
    marginTop: '10px'
  },
  projetTitle: {
    float: 'left',
    marginTop: '1.8em',
    marginRight: '1.5em',
    color: theme.palette.primary.main
  },
  mobiliersTitle: {
    color: theme.palette.primary.main,
    marginBottom: '1em'
  },
  projetsTitle: {
    color: theme.palette.primary.main,
    marginBottom: '1em'
  },
  inputFile: {
    display: 'none'
  },
  lastColumn: {
    width: '11.0625em',
    minWidth:'11.0625em',
    paddingLeft: '0!important',
    paddingRight: '0!important'
  }
});
