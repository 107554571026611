export default theme => ({
  container: {
    margin: 0
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1) + 'px ' + theme.spacing(10) + 'px',
    minWidth: 300,
  },
  icon: {
    fontSize: 30
  },
  menuItem: {
    padding: theme.spacing(2)
  },
  numeroSemaine: {
    display: 'inline-block',
    color: theme.palette.primary.main,
    fontSize: 45
  },
  datesContainer: {
    display: 'inline-block',
    marginLeft: theme.spacing(1)
  },
  dates: {},
  nombreChantiers: {
    color: theme.palette.primary.main
  },
  button:{
    color:'#FFFFFF'
  }
});