import {assetsForCss} from '../../../tools/Tools';
import {themeComplement} from '../../AppAdmin/css/theme';

export default theme => ({
  loginContainer: {
    background: 'url(' + assetsForCss('/img/default-background.jpg') + ')',
    backgroundSize: 'cover',
    height: '100vh',
    width: '100%'
  },
  paper: {
    padding: '2em'
  },
  title: {
    textAlign: 'center',
    marginTop: theme.spacing(2)
  },
  avatar: {
    backgroundColor: themeComplement.palette.tercerary,
    borderRadius: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '5em',
    width: '5em'
  },
  textField: {
    width: '100%'
  },
  buttonsLign: {
    marginTop: '3em'
  },
  button: {
    width: '100%',
    marginBottom: theme.spacing(1)
  }
})
