import {axiosClient} from '../../../../axios';
import {addNotification, axiosErrorHandler} from '../../../../reducers/notificationReducer';
import {themeComplement} from "../../AppAdmin/css/theme";

export function massUpdateCodeProginov(dispatch,data,cb){
    dispatch({
      type:'RESET_ERRORS',
      collection: 'code_proginovs'
    })
    axiosClient.post('code_proginovs/massupdate', data)
    .then(response => {
      addNotification(dispatch, {
        message: "Codes Proginovs mis à jour",
        bgColor: themeComplement.palette.notifications.success.color
      });
      dispatch({
        type:'LIST_FULFILLED',
        collection: 'code_proginovs',
        payload: response.data.list
      });
      dispatch({
        type:'ERRORS',
        collection: 'code_proginovs',
        payload: response.data.errors
      });
      cb();
    }).catch(err =>{
        axiosErrorHandler(dispatch, err)
        cb()
    })
}