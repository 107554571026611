import {Grid, Typography, MenuItem} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles/index";
import SettingsIcon from '@material-ui/icons/Settings';
import React, {Component} from 'react';
import CollectionCrud from '../../../common/Components/CollectionCrud';
import TextInput from '../../../common/Components/TextInput';
import InsertPhoto from '@material-ui/icons/InsertPhoto';
import ParametresCss from "../css/ParametresCss";

const pixels = [
  {
    value:100,
    label:'100px'
  },
  {
    value:200,
    label:'200px'
  },
  {
    value:300,
    label:'300px'
  },
  {
    value:400,
    label:'400px'
  }
];

class SchemaImages extends Component {
  state = {
    file:null,
    imagePreviewUrl:null
  };
  /**
   * Title de la modal de suppression
   * @return {[type]} [description]
   */
  getDeleteModalTitle(schema_image) {
    return <div><SettingsIcon/> <span>{'Suppression de l\'image : ' + (schema_image ? schema_image.nom : '')}</span>
    </div>;
  }

  getDeleteModalContent() {
    return <Typography>{'Êtes vous sûr de vouloir supprimer cette image ?'}</Typography>;
  }

  getCreateUpdateModalTitle(schema_image) {
    return <div><SettingsIcon/>
      <span>{!!schema_image ? 'Édition de l\'image : ' + schema_image.nom : 'Création d\'une nouvelle image'}</span>
    </div>;
  }


  /**
   * Retourne le formulaire
   * @returns {*}
   */
  getCreateUpdateModalContent(schema_image, schemaImagenStore, onChangeHandler) {
    let {nom, width, height} = schema_image;
    const {classes} = this.props;

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextInput
            id="nom"
            label="Libelle"
            value={nom}
            margin="normal"
            collectionStore={schemaImagenStore}
            name='nom'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="width"
            label="Largeur"
            value={width}
            select
            margin="normal"
            collectionStore={schemaImagenStore}
            name='width'
            onChangeHandler={onChangeHandler}
            fullWidth
          >
            {pixels.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="height"
            label="Hauteur"
            select
            value={height}
            margin="normal"
            collectionStore={schemaImagenStore}
            name='height'
            onChangeHandler={onChangeHandler}
            fullWidth
            >
            {pixels.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          </TextInput>
        </Grid>
        <Grid item xs={12} className={classes.rowFile}>
          <input
            accept="image/*"
            className={classes.inputFile}
            type="file"
            ref={input => this.inputfile = input}
            onChange={this.onchangeImage.bind(this)}
          />
          {this.getPreview(schema_image)}
        </Grid>
      </Grid>
    );
  }

  onchangeImage(e){
    let reader = new FileReader(),
      file = e.target.files[0],
      acceptedFiles = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
    if (acceptedFiles.indexOf(file.type) > -1) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        });
      };
      reader.readAsDataURL(file)
    }
  }

  /**
   * retourne la configuration des cellules
   * @return {[type]} [description]
   */
  getCellsConfig() {
    const {classes} = this.props;
    return [
      {
        datakey: "nom",
        title: "Nom",
        sortable: true,
        searchable: true,
        className:classes.thNom
      },
      {
        datakey:'image',
        title:'Image',
        sortable:false,
        searchable:false,
        format: schema_image => { 
          return schema_image.base_64 ? <img src={schema_image.base_64} width={'50px'} alt=""/> : null
        }
      }
    ]
  }

  tranformData(data){
    let form_data = new FormData();
    form_data.append('file', this.state.file);
    Object.keys(data).forEach( k => {
      form_data.append(k, data[k]);
    });
    return form_data
  }

  getPreview(schema_image) {
    const {classes} = this.props;

    if (!this.state.imagePreviewUrl && (!schema_image || !schema_image.base_64)) {
      return <InsertPhoto className={classes.defaultBackgroundIcon+' '+classes.imagepreview}
                          onClick={()=>{
                            this.inputfile.click()
                          }}
                          style={{width: '100px', height: '100px'}}/>
    } else {
      return <img style={{maxWidth: '100px', maxHeight: '100px'}}
                  src={this.state.imagePreviewUrl ? this.state.imagePreviewUrl : schema_image.base_64}
                  className={classes.imagepreview}
                  onClick={()=>{
                    this.inputfile.click()
                  }}
                  alt="Aperçu"/>
    }
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    return (
      <CollectionCrud
        collectionName={'schema_images'}
        showBtnEdit={true}
        showBtnAdd={true}
        showBtnDelete={true}
        deleteModalTitle={this.getDeleteModalTitle}
        deleteModalContent={this.getDeleteModalContent}
        createUpdateModalTitle={this.getCreateUpdateModalTitle}
        createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
        beforeSubmitTransformeData={this.tranformData.bind(this)}
        datatableConfig={{
          showPagination: true,
          showSearch: true,
          defaultSort: "nom",
          rowsPerPageOptions:[10, 25, 50]
        }}
        cellsConfig={this.getCellsConfig()}
      />
    );
  }
}
SchemaImages = withStyles(ParametresCss)(SchemaImages);

export default SchemaImages
