import {Grid, Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import React, {Component} from 'react';
import CollectionCrud from '../../../common/Components/CollectionCrud';
import TextInput from '../../../common/Components/TextInput';
import {collectionActions} from '../../../../reducers/collectionsReducer'


class Type extends Component {

  componentDidMount(){
    collectionActions(this.props.dispatch,'types','INDEX',{params:{without_trashed:true}})
  }

  /**
   * Title de la modal de suppression
   * @return {[type]} [description]
   */
  getDeleteModalTitle(type) {
    return <div><SettingsIcon/> <span>{'Suppression du type : ' + (type ? type.libelle : '')}</span>
    </div>;
  }

  getDeleteModalContent() {
    return <Typography>{'Êtes vous sûr de vouloir supprimer ce type ?'}</Typography>;
  }

  getCreateUpdateModalTitle(type) {
    return <div><SettingsIcon/>
      <span>{!!type ? 'Édition du type : ' + type.libelle : 'Création d\'un nouveau type'}</span>
    </div>;
  }


  /**
   * Retourne le formulaire
   * @returns {*}
   */
  getCreateUpdateModalContent(type, typesStore, onChangeHandler) {
    let {libelle, prefix_num_fiche} = type;

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextInput
            id="libelleType"
            label="Libelle"
            value={libelle}
            margin="normal"
            collectionStore={typesStore}
            name='libelle'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="prefix_num_fiche"
            label="Préfix Fiche"
            value={prefix_num_fiche}
            margin="normal"
            collectionStore={typesStore}
            name='prefix_num_fiche'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  /**
   * retourne la configuration des cellules
   * @return {[type]} [description]
   */
  getCellsConfig() {
    return [
      {
        datakey: "libelle",
        title: "Libelle",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "prefix_num_fiche",
        title: "Préfix Fiche",
        sortable: true,
        searchable: true,
      }
    ]
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    return (
      <CollectionCrud
        collectionName={'types'}
        loadDatas={false}
        showBtnEdit={true}
        showBtnAdd={true}
        showBtnDelete={true}
        deleteModalTitle={this.getDeleteModalTitle}
        deleteModalContent={this.getDeleteModalContent}
        createUpdateModalTitle={this.getCreateUpdateModalTitle}
        createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
        datatableConfig={{
          showPagination: true,
          showSearch: true,
          defaultSort: "libelle",
          rowsPerPageOptions:[10, 25, 50]
        }}
        cellsConfig={this.getCellsConfig()}
      />
    );
  }
}
Type = connect((store) => {
  return {
  }
})(Type);

export default Type
