import grey from '@material-ui/core/colors/grey';

export default theme => ({
  container: {
    margin: 0
  },
  button: {
    float: 'right'
  },
  emptyWeekIconContainer: {
    width: '100%',
    textAlign: 'center',
    color: grey[200]
  },
  emptyWeekTextContainer: {},
  emptyWeekText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  emptyWeekIcon: {
    fontSize: 250
  }
});