import {formatDate} from '../../../tools/Tools'
import {addNotification} from "../../../../reducers/notificationReducer";


var last_position = null;

export function watchGpsLocalisation(dispatch){
    if(navigator.geolocation){
        return navigator.geolocation.watchPosition(
            geolocSuccess.bind(this,dispatch),
            geolocError.bind(this,dispatch),
            {
                enableHighAccuracy: true,
                maximumAge: 1 * 60 * 1000,
                timeout: 10 * 1000
            }
        )  
    }else{
        addNotification(dispatch,{
            message:'Fonction Gps non disponible sur ce navigateur',
            bgColor: "#dd4b39"
        })
    }
}
/**
 * Callback success
 * @param {*} dispatch 
 * @param {*} position 
 */
function geolocSuccess(dispatch,position){
    last_position = {
        accuracy: position.coords.accuracy,
        geoloc_latitude:position.coords.latitude,
        geoloc_longitude:position.coords.longitude,
        dateUpdate: formatDate(new Date(),{
            year: 'numeric', 
            month: 'numeric', 
            day: 'numeric',
            hour: 'numeric', 
            minute: 'numeric', 
            second: 'numeric'
        })
    }

    dispatch({
        type:'CREATED',
        collection: 'gps',
        payload: last_position
    })
}
/**
 * Callback Erreur 
 */
function geolocError (dispatch,error) {
    let errors = {
        0: 'Erreur Inconnue',
        1: 'Permission refusée',
        2: 'Impossible d\'obtenir une position',
        3: 'Time out'
    }
    dispatch({
        type:'ERRORS',
        collection:'gps',
        payload: errors[error.code]
    })
}