import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  Fab,
  MenuItem
} from '@material-ui/core';
import {Cell, DataTable} from '@oniti/datatable-material';
import Done from '@material-ui/icons/Done';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import FlightTakeoff from '@material-ui/icons/FlightTakeoff';
import MergeType from '@material-ui/icons/MergeType';
import {connect} from 'react-redux';
import {collectionActions} from "../../../reducers/collectionsReducer";
import {Add, Delete, Edit} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import React, {Component} from 'react';
import TextInput from '../../common/Components/TextInput';
import MarcheCss from './css/MarcheCss';
import CreateProjet from './CreateProjet'
import Modal from '../../common/Components/Modal';
import CollectionsBookmark from '@material-ui/icons/CollectionsBookmark';

import {hasRights} from '../../common/Tools/Tools'

import {moveProjet,mergeProjet} from './actions/MarcheActions'

class Detail extends Component {
  state = {
    marche: null,
    listeProjets: null,
    openCreateModal: false,
    openDeleteModal: false,
    openMergeModal: false,
    openMoveModal:false,
    projet: null,
    marche_cible_uuid:null,
    projet_merge_uuid:null
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const {marchesStore, projetsStore} = nextProps;

    if (projetsStore.list !== prevState.listeProjets) {
      return {
        listeProjets: projetsStore.list
      };
    }

    if (!prevState.marche && marchesStore.detail !== prevState.marche) {
      return {
        marche: marchesStore.detail
      };
    }

    return null;
  }

  componentDidMount() {
    let {dispatch} = this.props;
    collectionActions(dispatch, 'projets', 'INDEX', {params: {marche_uuid: this.props.match.params.uuid}});
    collectionActions(dispatch, 'marches', 'SHOW', {uuid: this.props.match.params.uuid})
    collectionActions(dispatch, 'marches', 'INDEX')
  }

  componentWillUnmount() {
    let {dispatch} = this.props;
    collectionActions(dispatch, 'marches', 'RESET_ERRORS');
  }

  /**
   * Fermture de la modal delete
   * @return {[type]} [description]
   */
  onCloseDeleteModalHandler() {
    this.setState({
      openDeleteModal: false,
      projet: null
    })
  }

  /**
   * submit de la modal delete
   * @return {[type]} [description]
   */
  onSubmitHandler() {
    collectionActions(this.props.dispatch, 'projets', 'DELETE', {uuid: this.state.projet.uuid}, () => {
      this.onCloseDeleteModalHandler()
    })
  }

  /**
   * Handler sur la mise à jour des champs du marche
   * @param  {[type]} name  [description]
   * @param  {[type]} event [description]
   * @return {[type]}       [description]
   */
  onChangeMarcheHandler(name, event) {
    let {value} = event.target;

    this.setState({
      marche: {
        ...this.state.marche,
        [name]: value
      }
    });
  }

  /**
   * Soumission du formulaire
   * @return {[type]} [description]
   */
  onClickUpdateMarcheHandler() {
    const {dispatch} = this.props;
    collectionActions(dispatch, 'marches', 'UPDATE', this.state.marche)
  }

  /**
   * Handler sur le press des keys
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  onKeyPressMarcheHandler(e) {
    if (e.which === 13 || e.keyCode === 13 || e.key === 'Enter') {
      this.onClickUpdateMarcheHandler()
    }
  }

  /**
   * Fermeture de la modal Add
   * @return {[type]} [description]
   */
  onCloseAddHandler() {
    this.setState({
      openCreateModal: false
    })
  }

  /**
   * Bouton d'ajout d'un utilisateur
   * @return {[type]} [description]
   */
  getBtnAdd() {
    let {classes} = this.props;

    return (
      <Fab
        color="primary"
        aria-label="add"
        size="small"
        onClick={() => {
          this.setState({openCreateModal: true})
        }}
        className={classes.addButton}
        title="Ajouter"
      >
        <Add style={{fontSize: 32}}/>
      </Fab>
    );
  }

  /**
   * Click sur le bouton retour
   * @return {[type]} [description]
   */
  onClickReturnHandler() {
    this.props.history.push('/marches')
  }

  /**
   * Déplacer le projet sur un autre marché
   * @param {*} projet 
   */
  onClickMoveHandler(projet){
    this.setState({
      openMoveModal: true,
      projet
    })
  }

  onCloseMoveModalHandler(){
    this.setState({
      openMoveModal: false,
      projet:null,
      marche_cible_uuid:null
    })
  }
  onChangeSelect(name,e){
    const {value} = e.target
    this.setState({
      [name]:value
    })
  }

  getOptionsMarche(){
    return this.props.marchesStore.list.filter( marche => marche.uuid !== this.state.projet.marche_uuid).map( m => {
      return (
        <MenuItem value={m.uuid} key={m.uuid}>
          {m.libelle}
        </MenuItem>
      )
    })
  }

  onSubmitMoveHandler(){
    moveProjet(this.props.dispatch,this.state.projet.uuid,{
      marche_cible_uuid:this.state.marche_cible_uuid
    },()=>{
      this.onCloseMoveModalHandler()
    })
  }

  getMoveModal(){
    if(!this.state.openMoveModal || ! this.state.projet) return null

    const {classes,marchesStore} = this.props

    return (
      <Modal
      openModal={this.state.openMoveModal}
      onCloseHandler={this.onCloseMoveModalHandler.bind(this)}
      onSubmitHandler={this.onSubmitMoveHandler.bind(this)}
      fullWidth={true}
      maxWidth='sm'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          <FlightTakeoff/> <span>Déplacement du projet {this.state.projet.libelle}</span>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <TextInput
              select
              label="Marché Cible"
              className={classes.input}
              value={this.state.marche_cible_uuid}
              margin="normal"
              collectionStore={marchesStore}
              name='marche_cible_uuid'
              onChangeHandler={this.onChangeSelect.bind(this)}
              fullWidth
            >
              {this.getOptionsMarche()}
            </TextInput>
          </Grid>
        </DialogContent>
      </Modal>
    )
  }

  getProjetsOptions(){
    return this.props.projetsStore.list.filter( projet => projet.uuid !== this.state.projet.uuid).map( projet => {
      return (
        <MenuItem value={projet.uuid} key={projet.uuid}>
          {projet.libelle}
        </MenuItem>
      )
    })
  }

  onSubmitMergeHandler(){
    mergeProjet(this.props.dispatch,this.state.projet.uuid,{
      projet_merge_uuid:this.state.projet_merge_uuid
    },()=>{
      this.onCloseMergeHandler()
    })
    
  }

  /**
   * Déplacer le projet sur un autre marché
   * @param {*} projet 
   */
  onClickMergeHandler(projet){
    this.setState({
      openMergeModal: true,
      projet
    })
  }

  onCloseMergeHandler(){
    this.setState({
      openMergeModal: false,
      projet:null,
      projet_merge_uuid:null
    })
  }

  getMergeModal(){
    if(!this.state.openMergeModal || ! this.state.projet) return null

    const {classes,marchesStore,projetsStore} = this.props

    return (
      <Modal
      openModal={this.state.openMergeModal}
      onCloseHandler={this.onCloseMergeHandler.bind(this)}
      onSubmitHandler={this.onSubmitMergeHandler.bind(this)}
      fullWidth={true}
      maxWidth='lg'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          <FlightTakeoff/> <span>Fusion du projet {this.state.projet.libelle}</span>
        </DialogTitle>
        <DialogContent>
          <Grid container className={classes.root}>
            <Grid item xs={4}>
              <TextInput
                id="libelle"
                label="Libellé"
                className={classes.labelInput}
                value={this.state.projet.libelle}
                margin="normal"
                collectionStore={projetsStore}
                name='libelle'
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                  id="contact"
                  label="Contact"
                  className={classes.labelInput}
                  value={this.state.projet.contact}
                  margin="normal"
                  collectionStore={projetsStore}
                  name='contact'
                  disabled
                />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                id="type"
                label="Type"
                className={classes.labelInput}
                value={this.state.projet.type === 'mep_initiale' ? 'Mise en place initial' : 'Exploitation'}
                margin="normal"
                collectionStore={projetsStore}
                name='type'
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                select
                label="Projet a Fusionner"
                className={classes.input}
                value={this.state.projet_merge_uuid}
                margin="normal"
                collectionStore={marchesStore}
                name='projet_merge_uuid'
                onChangeHandler={this.onChangeSelect.bind(this)}
                fullWidth
              >
                {this.getProjetsOptions()}
              </TextInput>
            </Grid>
          </Grid>
        </DialogContent>
      </Modal>
    )
  }

  /**
   * retourne le bouton add
   * @param  {[type]} projet [description]
   * @return {[type]}        [description]
   */
  getActionsBtn(projet) {
    const {classes} = this.props,
          hasRightsFusionMove = hasRights('admin-deplacer-fusionner-projet',this.props.user)
    let btn_fused_move = []
    if(hasRightsFusionMove){
      btn_fused_move =  [
        <IconButton
          aria-label="Déplacer"
          key="move"
          color="primary"
          onClick={this.onClickMoveHandler.bind(this, projet)}
          className={classes.button}
          title="Déplacer"
        >
        <FlightTakeoff/>
        </IconButton>,
        <IconButton
          aria-label="Fusionner"
          key="fuse"
          color="primary"
          onClick={this.onClickMergeHandler.bind(this, projet)}
          className={classes.button}
          title="Fusionner"
        >
          <MergeType/>
        </IconButton>
      ]
    }
    return [
      <IconButton
        aria-label="Delete"
        onClick={this.onClickDeleteHandler.bind(this, projet)}
        className={classes.button}
        key="delete"
        title="Supprimer"
      >
        <Delete/>
      </IconButton>,
      <IconButton
        aria-label="Edit"
        key="edit"
        color="primary"
        onClick={this.onClickEditHandler.bind(this, projet)}
        className={classes.button}
        title="Éditer"
      >
        <Edit/>
      </IconButton>,
      ...btn_fused_move
    ];
  }

  /**
   * Click sur le bouton delete
   * @return {[type]} [description]
   */
  onClickDeleteHandler(projet) {
    this.setState({
      openDeleteModal: true,
      projet
    })
  }

  /**
   * Click sur le bouton édition
   * @param  {[type]} projet [description]
   * @return {[type]}        [description]
   */
  onClickEditHandler(projet) {
    this.props.history.push('/projets/' + projet.uuid)
  }

  /**
   * Titire de la partie Projets
   * @return {[type]} [description]
   */
  getProjetsTitle() {
    const {classes} = this.props;
    return (
      <Typography
        variant="button"
        gutterBottom
        className={classes.projetsTitle}
      >
        Projets
      </Typography>
    )
  }

  getType(projet) {
    let result = '';

    switch (projet.type) {
      case 'exploitation':
        result = 'Exploitation';
        break;
      default:
        result = 'Mise en place initiale';
        break;
    }

    return result
  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {classes, marchesStore} = this.props;

    if (!this.state.marche) return <CircularProgress/>;

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.marcheTitle}
          >
            Marché
          </Typography>
          <Button
            title='Retour à la liste des marchés'
            color='primary'
            className={classes.returnButton}
            onClick={this.onClickReturnHandler.bind(this, '/projets')}
          >
            <ChevronLeft/> Retour
          </Button>
        </Grid>
        <Grid item xs={12} style={{marginBottom: '1em'}}>
          <TextInput
            id="libelle"
            label="Libellé"
            className={classes.labelInput}
            value={this.state.marche.libelle}
            margin="normal"
            collectionStore={marchesStore}
            name='libelle'
            onChangeHandler={this.onChangeMarcheHandler.bind(this)}
            onKeyPress={this.onKeyPressMarcheHandler.bind(this)}
          />
          <TextInput
            id="prefix_num_fiche"
            label="Préfixe des fiches"
            className={classes.labelInput}
            value={this.state.marche.prefix_num_fiche}
            margin="normal"
            collectionStore={marchesStore}
            name='prefix_num_fiche'
            onChangeHandler={this.onChangeMarcheHandler.bind(this)}
            onKeyPress={this.onKeyPressMarcheHandler.bind(this)}
          />
          <div className={classes.societeInput}>
            <TextInput
              id="societe_uuid"
              label="Société"
              collectionStore={marchesStore}
              value={this.state.marche.societe_uuid}
              margin="normal"
              name='societe_uuid'
              onChangeHandler={this.onChangeMarcheHandler.bind(this)}
              type="autocomplete"
              autocompleteProps={{
                collectionName: "societes",
              }}
              fullWidth
              style={{width: '100%'}}
            />
          </div>
          <Button
            title='Valider les changements'
            color='primary'
            variant="contained"
            size="small"
            onClick={this.onClickUpdateMarcheHandler.bind(this)}
          >
            <Done/>
          </Button>
          <Divider/>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <DataTable
                datas={this.state.listeProjets ? this.state.listeProjets : []}
                showPagination={true}
                showSearch={true}
                defaultSort="libelle"
                sortType='asc'
                rowsPerPageOptions={[10, 25, 50]}
                extraNodes={[
                  {
                    element: this.getBtnAdd(),
                    position: 'top-right'
                  },
                  {
                    element: this.getProjetsTitle(),
                    position: 'top-left'
                  }
                ]}
              >
                <Cell
                  datakey='libelle'
                  title='Libellé'
                  searchable={true}
                  sortable={true}
                />
                <Cell
                  datakey='contact'
                  title='Contact'
                  searchable={true}
                  sortable={true}
                />
                <Cell
                  format={this.getType.bind(this)}
                  title='Type'
                  searchable={true}
                  sortable={true}
                />
                <Cell
                  format={this.getActionsBtn.bind(this)}
                />
              </DataTable>
            </Grid>
          </Grid>
        </Grid>
        <CreateProjet
          open={this.state.openCreateModal}
          onCloseHandler={this.onCloseAddHandler.bind(this)}
        />
        <Modal
          openModal={this.state.openDeleteModal}
          onCloseHandler={this.onCloseDeleteModalHandler.bind(this)}
          onSubmitHandler={this.onSubmitHandler.bind(this)}
          fullWidth={true}
          maxWidth='sm'
        >
          <DialogTitle key="title" id="alert-dialog-slide-title">
            <CollectionsBookmark/> <span>Suppression du projet</span>
          </DialogTitle>
          <DialogContent>
            <Typography>Êtes vous sûr de vouloir supprimer ce projet ?</Typography>
          </DialogContent>
        </Modal>
        {this.getMoveModal()}
        {this.getMergeModal()}
      </Grid>
    );
  }
}

Detail = connect((store) => {
  return {
    marchesStore: store.collections.marches,
    projetsStore: store.collections.projets,
    user: store.auth.user
  }
})(Detail);
Detail = withStyles(MarcheCss)(Detail);

export default Detail
