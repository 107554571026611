import React from 'react';

/**
 * extrait les messages d'erreur pour le champ concerné
 * @param  {[type]} errors [description]
 * @param  {[type]} field  [description]
 * @return {[type]}        [description]
 */
export function getErrorsMessage(errors, field) {
  if (!errors || !field || !errors[field]) return null;

  if (Array.isArray(errors[field])) {
    return errors[field].map((err, index) => {
      return <span key={index}>{err}</span>
    });
  } else return errors[field];
}

/**
 * Génère le path pour le css afin d'éviter des erreur de répertoire
 * @param  {[type]} path [description]
 * @return {[type]}      [description]
 */
export function assetsForCss(path) {
  let prefix = process.env.NODE_ENV === 'production' ? '/client' : '';
  return prefix.concat(path);
}

/**
 * Formatte une date
 * @param rawDate
 * @param options du type {year: 'numeric', month: 'numeric', day: 'numeric'}
 * @returns {string}
 */
export function formatDate(rawDate, options) {
  let date = new Date(rawDate);
  return date.toLocaleDateString('fr-FR', options)
}
