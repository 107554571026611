import React, {Component} from 'react';
import {connect} from "react-redux";
import {Fab, Grid, Typography, withStyles} from "@material-ui/core";
import ParametresCss from "../css/ParametresCss";
import CollectionCrud from '../../../common/Components/CollectionCrud';
import {collectionActions} from "../../../../reducers/collectionsReducer";
import SettingsIcon from '@material-ui/icons/Settings';
import TextInput from "../../../common/Components/TextInput";
import {CloudDownload, InsertPhoto} from "@material-ui/icons";

class Societe extends Component {
  state = {
    file: null,
    logoPreview: null
  };

  componentDidMount(){
    collectionActions(this.props.dispatch,'societes','INDEX')
  }

  /**
   * Title de la modal de suppression
   * @return {[type]} [description]
   */
  getDeleteModalTitle(societe) {
    return <div><SettingsIcon/> <span>{'Suppression de la société : ' + (societe ? societe.denomination : '')}</span>
    </div>;
  }

  getDeleteModalContent() {
    return <Typography>{'Êtes vous sûr de vouloir supprimer cette société ?'}</Typography>;
  }

  getCreateUpdateModalTitle(societe) {
    return <div><SettingsIcon/>
      <span>{!!societe ? 'Édition de la société : ' + societe.denomination : 'Création d\'une nouvelle société'}</span>
    </div>;
  }

  tranformData(data){
    let form_data = new FormData();
    form_data.append('file', this.state.file);
    Object.keys(data).forEach( k => {
      form_data.append(k, !data[k] ? '' : data[k]);
    });
    return form_data
  }

  /**
   * Apperçu du logo
   * @return {[type]} [description]
   */
  getPreview() {
    const {classes} = this.props;

    if (!this.state.logoPreview && (!this.props.societe || !this.props.societe.logo_url)) {
      return <InsertPhoto
        className={classes.logoPreview}
      />
    } else {
      return <img
        style={{maxWidth: '100%'}}
        src={this.state.logoPreview ? this.state.logoPreview : this.props.societe.logo_url}
        alt="Aperçu"
      />
    }
  }

  /**
   * Handler de changement de logo
   * @param  {[type]} e [description]
   * @return {[type]}          [description]
   */
  onChangeFile(e) {
    let reader = new FileReader(),
      file = e.target.files[0],
      acceptedFiles = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
    if (acceptedFiles.indexOf(file.type) > -1) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          logoPreview: reader.result
        });
      };
      reader.readAsDataURL(file)
    }
  }

  /**
   * Retourne le formulaire
   * @returns {*}
   */
  getCreateUpdateModalContent(societe, societesStore, onChangeHandler) {
    let {denomination, adresse, adresse_complement, code_postal, ville, telephone} = societe;
    const {classes} = this.props;

    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <TextInput
              id="denomination"
              label="Dénomination"
              value={denomination}
              margin="normal"
              collectionStore={societesStore}
              name='denomination'
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="adresse"
              label="Adresse"
              value={adresse}
              margin="normal"
              collectionStore={societesStore}
              name='adresse'
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="adresse_complement"
              label="Adresse complément"
              value={adresse_complement}
              margin="normal"
              collectionStore={societesStore}
              name='adresse_complement'
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="code_postal"
              label="Code postal"
              value={code_postal}
              margin="normal"
              collectionStore={societesStore}
              name='code_postal'
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="ville"
              label="Ville"
              value={ville}
              margin="normal"
              collectionStore={societesStore}
              name='ville'
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="telephone"
              label="Téléphone"
              value={telephone}
              margin="normal"
              collectionStore={societesStore}
              name='telephone'
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={6} style={{position: 'relative'}}>
          <Grid item xs={12}>
            <input
              accept="image/*"
              className={classes.inputFile}
              capture="camera"
              type="file"
              ref={input => this.inputfile = input}
              onChange={this.onChangeFile.bind(this)}
            />
            <Fab
              color="primary"
              aria-label="photo"
              title="Envoyer le logo"
              className={classes.addButton}
              onClick={() => {
                this.inputfile.click()
              }}
            >
              <CloudDownload className={classes.addButtonIcon}/>
            </Fab>
            {this.getPreview()}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  /**
   * retourne la configuration des cellules
   * @return {[type]} [description]
   */
  getCellsConfig() {
    return [
      {
        datakey: "denomination",
        title: "Dénomination",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "adresse",
        title: "Adresse",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "adresse_complement",
        title: "Complément",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "code_postal",
        title: "Code postal",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "ville",
        title: "Ville",
        sortable: true,
        searchable: true,
      }
    ]
  }
  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    return (
      <CollectionCrud
        collectionName={'societes'}
        showBtnEdit={true}
        showBtnAdd={true}
        showBtnDelete={true}
        deleteModalTitle={this.getDeleteModalTitle}
        deleteModalContent={this.getDeleteModalContent}
        createUpdateModalTitle={this.getCreateUpdateModalTitle}
        createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
        beforeSubmitTransformeData={this.tranformData.bind(this)}
        datatableConfig={{
          showPagination: true,
          showSearch: true,
          defaultSort: "denomination",
          rowsPerPageOptions:[25, 50]
        }}
        cellsConfig={this.getCellsConfig()}
        modalMaxWidth={'md'}
        actionsCallback={()=>{
          this.setState({
            file: null,
            logoPreview: null
          })
        }}
      />
    );
  }
}

Societe = connect((store) => {
  return {
    societe: store.collections.societes.detail
  }
})(Societe);

Societe = withStyles(ParametresCss)(Societe);

export default Societe