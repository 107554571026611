import {themeComplement} from "../../AppAdmin/css/theme";
export default theme => ({
  modalContainer: {
    marginTop: '8px',
    height: '100%'
  },
  progress:{
    margin:'auto'
  },
  selectContainer: {
    width: '100%'
  },
  tab: {
    maxWidth: '100%'
  },
  tabError:{
      color: themeComplement.palette.notifications.error.color+'!important',
  },
  card: {
    width: '100%',
    marginBottom: theme.spacing(2),
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: '1em',
    marginLeft: '1em',
    width: '100%'
  },
  input: {
    marginTop: 0
  },
  checkbox: {
    color: theme.palette.primary.main
  },
  // UpdatePhoto
  photoContainer: {
    position: 'relative',
    textAlign: 'center'
  },
  addButton: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 2,
    color:'#ECEFF1'
  },
  addButtonIcon: {
    fontSize: 32
  },
  inputFile: {
    display: 'none'
  },
  defaultBackgroundIcon: {
    color: '#cfd3d6',
  },
  // UpdateSchema
  schemaContainer: {
    position: 'relative',
    paddingTop: '5em',
  },
  appBar: {
    backgroundColor: 'white',
    width: '70%',
    position: 'absolute',
    left: 0,
    top: 0,
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      left: 0,
      top: 0,
    },
  },
  sketchContainer: {
    border:'solid 1px black',
    margin:'auto'
  },
  active: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.54)'
    }
  },
  noactive: {},
  colorPickerButton: {
    height: theme.spacing(4),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  verticaleline: {
    transform: 'rotate(90deg)'
  },
  // UpdateOptions
  optionsLibelle: {
    marginTop: theme.spacing(1)
  },
  optionsButton: {
    minWidth: 0,
    width: '100%'
  },
  optionsInput: {
    marginTop: '2px'
  },
  // Geolocalisation
  geolocButton: {
    marginTop: '15px',
    color:'#FFFFFF'
  },
  error:{
    color:'red',
    height: '100%',
    textAlign: 'center',
    fontSize: 'initial',
    verticalAlign: 'middle'
  }
});
