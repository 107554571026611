import {Grid, IconButton, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {buildRoute} from '../../../router/Tools'
import CollectionCrud from '../../common/Components/CollectionCrud';
import TextInput from '../../common/Components/TextInput';
import MarcheCss from './css/MarcheCss';

class Marche extends Component {

  /**
   * Title de la modal de suppression
   * @return {[marche]} [description]
   */
  getDeleteModalTitle(marche) {
    return <div><SettingsIcon/> <span>{'Suppression du marché : ' + (marche ? marche.libelle : '')}</span></div>;
  }

  getDeleteModalContent() {
    return <Typography>{'Êtes vous sûr de vouloir supprimer ce marché ?'}</Typography>;
  }

  getCreateModalTitle(marche) {
    return <div><SettingsIcon/>
      <span>{!!marche ? 'Édition du marché : ' + marche.libelle : 'Création d\'un nouveau marché'}</span>
    </div>;
  }

  /**
   * Retourne le bouton d'information
   * @param  {[type]} projet [description]
   * @return {[type]}        [description]
   */
  getInfoBtn(projet) {
    let {classes} = this.props;
    return (
      <IconButton
        key="detail"
        aria-label="Détail"
        color="primary"
        onClick={() => {
          this.props.history.push(buildRoute('/marches/:uuid', {uuid: projet.uuid}))
        }}
        title="Détail"
        className={classes.floatright}
      >
        <EditIcon/>
      </IconButton>
    );
  }

  /**
   * Retourne le formulaire de création
   * @returns {*}
   */
  getCreateModalContent(marche, marchesStore, onChangeHandler) {
    let {libelle, prefix_num_fiche, societe_uuid} = marche;
    const { classes } = this.props

    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TextInput
            id="libelle"
            label="Libelle"
            value={libelle}
            margin="normal"
            collectionStore={marchesStore}
            name='libelle'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="prefix_num_fiche"
            label="Préfixe des fiches"
            value={prefix_num_fiche}
            margin="normal"
            collectionStore={marchesStore}
            name='prefix_num_fiche'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="societe_uuid"
            label="Société"
            collectionStore={marchesStore}
            value={societe_uuid}
            margin="normal"
            name='societe_uuid'
            onChangeHandler={onChangeHandler}
            classNameSuggestionContainer={classes.suggestionContainer}
            type="autocomplete"
            autocompleteProps={{
              collectionName: "societes",
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  /**
   * retourne la configuration des cellules
   * @return {[marche]} [description]
   */
  getCellsConfig() {
    return [
      {
        datakey: "libelle",
        title: "Libelle",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "prefix_num_fiche",
        title: "Préfixe Fiche",
        sortable: true,
        searchable: true,
      }
    ]
  }

  getMarchesTitle() {
    const {classes} = this.props;
    return (
      <Typography
        variant="button"
        gutterBottom
        className={classes.marchesTitle}
      >
        Marchés
      </Typography>
    )
  }

  /**
   * Fonction de rendu Final
   * @return {[marche]} [description]
   */
  render() {
    return (
      <CollectionCrud
        collectionName={'marches'}
        showBtnEdit={false}
        showBtnAdd={true}
        showBtnDelete={true}
        deleteModalTitle={this.getDeleteModalTitle}
        deleteModalContent={this.getDeleteModalContent}
        createUpdateModalTitle={this.getCreateModalTitle}
        createUpdateModalContent={this.getCreateModalContent.bind(this)}
        modalMaxWidth="sm"
        additionnalControllers={[
          this.getInfoBtn.bind(this)
        ]}
        datatableConfig={{
          showPagination: true,
          showSearch: true,
          defaultSort: "libelle",
          rowsPerPageOptions:[10, 25, 50]
        }}
        dataTableExtraNodes={[
          {
            element: this.getMarchesTitle(),
            position: 'top-left'
          }
        ]}
        cellsConfig={this.getCellsConfig()}
      />
    );
  }
}

Marche = withStyles(MarcheCss)(Marche);
Marche = withRouter(Marche);

export default Marche
