import {themeComplement} from '../../../admin/AppAdmin/css/theme';

export default theme => ({
  errorIcon: {
    width: '4em',
    height: '4em'
  },
  errorContainer: {
    background: 'url(' + themeComplement.palette.backgroundPath + ')',
    backgroundSize: 'cover',
    height: '100vh',
    width: '100%'
  },
  paper: {
    width: '20%',
    padding: '2em'
  },
})
