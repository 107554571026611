export default theme => ({
  modalAppBar: {
    backgroundColor: 'white',
    color: theme.palette.primary.main
  },
  closeContainer: {
    flex: 1
  },
  modalValidationButton: {
    float: 'right',
    marginLeft: theme.spacing(4)
  },
  modalContentContainer: {
    padding: '24px'
  },
  toolbar: theme.mixins.toolbar
});
