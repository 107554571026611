import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AppBar, Button, Dialog, Grid, IconButton, Slide, Toolbar} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import FullScreenCss from './css/FullScreenCss';
import {Close} from '@material-ui/icons';

class FullScreen extends Component {
  state = {
    mobilier_uuid: null
  };

  /**
   * Handler sur la fermeture de la modal
   * @return {[type]} [description]
   */
  handleClose() {
    const {onCloseHandler} = this.props;
    onCloseHandler()
  }

  /**
   * Transition pour l'animation
   * @param {[type]} props [description]
   */
  Transition(props) {
    return <Slide direction="up" {...props} />;
  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {classes, openModal, onSubmitHandler} = this.props;
    return (
      <Dialog
        fullScreen
        open={openModal}
        onClose={this.handleClose.bind(this)}
        TransitionComponent={this.Transition}
        disableEnforceFocus={true}
      >
        <AppBar className={classes.modalAppBar}>
          <Toolbar>
            <Grid item className={classes.closeContainer}>
              <IconButton
                onClick={this.handleClose.bind(this)}
                aria-label="Close"
                title="Fermer le mobilier"
              >
                <Close/>
              </IconButton>
            </Grid>
            <Grid item>
              {this.props.appBarContent}
            </Grid>
            <Grid item>
              <Button
                title="Valider les modifications"
                color="primary"
                variant="contained"
                onClick={onSubmitHandler}
                className={classes.modalValidationButton}
              >
                Valider
              </Button>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.toolbar}/>
        {this.props.children}
      </Dialog>
    );
  }
}

FullScreen = withStyles(FullScreenCss)(FullScreen);

FullScreen.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  appBarContent: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};
export default FullScreen
