import axios from 'axios'
import {axiosClient} from '../../../../axios';
import {addNotification, axiosErrorHandler} from '../../../../reducers/notificationReducer';
import {themeComplement} from "../../AppAdmin/css/theme";

export function createMobiliers(dispatch, data, cb) {
  axiosClient.post('mobiliers', data)
    .then(response => {
      addNotification(dispatch, {
        message: "Mobiliers Créés",
        bgColor: themeComplement.palette.notifications.success.color
      });
      cb()
    }).catch(error =>{
      axiosErrorHandler(dispatch, error)
      if (!!error.response &&
        !!error.response.data &&
        !!error.response.data.errors) {
        dispatch({
          type: 'ERRORS',
          collection: 'mobiliers',
          payload: error.response.data.errors
        })
      }
    })
}

export function updatePhoto(dispatch, uuid, data, cb) {
  axiosClient.post('mobiliers/' + uuid + '/photos', data)
    .then(response => {
        if(response.data.updated){
            dispatch({
              type:'UPDATED',
              collection: 'mobiliers',
              payload: response.data.mobilier
            });
            addNotification(dispatch, {
                message: "Photo mise à jour",
                bgColor: themeComplement.palette.notifications.success.color
            });
        }
      cb()
    }).catch(err => axiosErrorHandler(dispatch, err))
}

export function getAdressFromGps(dispatch, geoloc_latitude, geoloc_longitude, cb) {
  //https://developers.google.com/maps/documentation/geocoding/intro
  axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
    params: {
      key:'AIzaSyAYBX5zmuw5Kz5lxUmiYnxK6xHsOlQ4OP0',
      latlng: geoloc_latitude + ',' + geoloc_longitude,
      sensor: true,
      result_type:'street_address|route|intersection'
    }
  })
    .then(response => {
      cb(response.data.results)
    })
    .catch(err => axiosErrorHandler(dispatch, err))
}
