import {axiosClient} from '../../../../axios'
import {addNotification,axiosErrorHandler} from "../../../../reducers/notificationReducer";
import {themeComplement} from "../../AppAdmin/css/theme";

/**
 * Déplace un projet sur un autre marché.
 */
export function moveProjet(dispatch,projet_uuid,data,cb){
    axiosClient.put('/projet/'+projet_uuid+'/move',data)
    .then(response => {
        addNotification(dispatch, {
            message: "Projet Déplacé",
            bgColor: themeComplement.palette.notifications.success.color
        });
        dispatch({
            type:'LIST_FULFILLED',
            collection:'projets',
            payload:response.data
        })
        cb()
    }).catch(error =>{
        axiosErrorHandler(dispatch, error)
        if (!!error.response &&
            !!error.response.data &&
            !!error.response.data.errors) {
            dispatch({
            type: 'ERRORS',
            collection: 'marches',
            payload: error.response.data.errors
            })
        }
        cb()
    })
}

/**
 * Fusionne deux projets enssemble.
 */
export function mergeProjet(dispatch,projet_uuid,data,cb){
    axiosClient.put('/projet/'+projet_uuid+'/merge',data)
    .then(response => {
        addNotification(dispatch, {
            message: "Projets Fusionnés",
            bgColor: themeComplement.palette.notifications.success.color
        });
        dispatch({
            type:'LIST_FULFILLED',
            collection:'projets',
            payload:response.data
        })
        cb()
    }).catch(error =>{
        axiosErrorHandler(dispatch, error)
        if (!!error.response &&
            !!error.response.data &&
            !!error.response.data.errors) {
            dispatch({
            type: 'ERRORS',
            collection: 'marches',
            payload: error.response.data.errors
            })
        }
        cb()
    })
}