import {Fab, Card, CardContent, CircularProgress, Grid, Typography,DialogTitle,DialogContent,MenuItem} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles/index";
import MyLocation from '@material-ui/icons/MyLocation';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import TextInput from '../../common/Components/TextInput/index';
import MobilierCss from "./css/MobilierCss";
import {getAdressFromGps} from './actions/MobilierActions';
import Modal from '../../common/Components/Modal'

class UpdateGeolocalisation extends Component {
  state={
    loadding:false,
    showModalAdresse:false,
    adresses:null,
    id_adresse:null
  }
  
  getGpsLocalisation(){
    if(this.props.gps_infos){
      this.setState({loadding:true})
      getAdressFromGps(this.props.dispatch, this.props.gps_infos.geoloc_latitude, this.props.gps_infos.geoloc_longitude, (response) => {
        this.setState({showModalAdresse:true,adresses:response.concat({
          formatted_address:'Aucune'
        })})
      })
    }
  }
  /**
   * Retourne le bloc GPS
   * @return {[type]} [description]
   */
  getGpsBtn() {
    let {classes, mobiliersStore, onChangeHandler, mobilier,gps_error} = this.props;
    mobilier = !!mobilier ? mobilier : {};
    let gps_btn = this.props.gps_infos ? (
      <Fab
        color="primary"
        aria-label="localisation"
        className={classes.geolocButton}
        onClick={this.getGpsLocalisation.bind(this)}
        key="edit-deplacement"
        title="Ma localisation"
      >
        <MyLocation/>
      </Fab>) : <p className={classes.error}><span>Gps indisponible pour la raison suivante : {gps_error}</span></p>

    if(this.state.loadding) gps_btn = <CircularProgress/>


    let localisation = (
      <Grid item xs={12} lg={12}>
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={4}>
              <Typography
                variant="button"
                gutterBottom
                className={classes.title}
              >
                Localisation
              </Typography>
              <Grid item xs={4}>
                <TextInput
                  id="geoloc_adresse"
                  type="text"
                  label="Adresse"
                  value={mobilier.geoloc_adresse}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='geoloc_adresse'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="geoloc_adresse_complement"
                  type="text"
                  label="Complément"
                  value={mobilier.geoloc_adresse_complement}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='geoloc_adresse_complement'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={1}>
                <TextInput
                  id="geoloc_code_postal"
                  type="text"
                  label="Code postal"
                  value={mobilier.geoloc_code_postal}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='geoloc_code_postal'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  id="geoloc_ville"
                  type="text"
                  label="Ville"
                  value={mobilier.geoloc_ville}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='geoloc_ville'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="geoloc_latitude"
                  type="text"
                  label="Latitute"
                  className={classes.labelInput}
                  value={mobilier.geoloc_latitude}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='geoloc_latitude'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="geoloc_longitude"
                  type="text"
                  label="Longitude"
                  className={classes.labelInput}
                  value={mobilier.geoloc_longitude}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='geoloc_longitude'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                {gps_btn}
              </Grid>
            </Grid>
            <Grid item xs={4}>
                <TextInput
                  id="geoloc_azimut"
                  type="text"
                  label="Azimut"
                  value={mobilier.geoloc_azimut}
                  margin="normal"
                  collectionStore={mobiliersStore}
                  name='geoloc_azimut'
                  onChangeHandler={onChangeHandler}
                  fullWidth
                />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )

    return (
      <Grid container spacing={4}>
        <Grid item xs={12} lg={12}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={4}>
                <Typography
                  variant="button"
                  gutterBottom
                  className={classes.title}
                >
                  Informations
                </Typography>
                <Grid item xs={4}>
                  <TextInput
                    id="geoloc_nom_arret"
                    type="text"
                    label="Nom de l'arret"
                    value={mobilier.geoloc_nom_arret}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='geoloc_nom_arret'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    id="geoloc_code_emplacement"
                    type="text"
                    label="Code emplacement"
                    value={mobilier.geoloc_code_emplacement}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='geoloc_code_emplacement'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    id="geoloc_stationnement"
                    type="text"
                    label="Stationnement"
                    value={mobilier.geoloc_stationnement}
                    margin="normal"
                    collectionStore={mobiliersStore}
                    name='geoloc_stationnement'
                    onChangeHandler={onChangeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {localisation}
      </Grid>
    );
  }
  /**
   * Retourne les options
   */
  getOptions(){
    if(!this.state.adresses) return []
    else{
      return this.state.adresses.map( (adresse,index) => {
        return (
          <MenuItem value={index} key={index}>
            {adresse.formatted_address}
          </MenuItem>
        )
      })
    }
  }
  /**
   * Handler de changement d'adresse.
   * @param {*} name 
   * @param {*} e 
   */
  onChangeHandlerAdresse(name,e){
    this.setState({
      [name]:e.target.value
    })
  }
  sumbmitSelectAdresse(){
    let adresse_selected = this.state.adresses[this.state.id_adresse]
    let geoloc_adresse = '',
            geoloc_code_postal = '',
            geoloc_ville = '',
            geoloc_adresse_complement = ''

    if (adresse_selected && adresse_selected.address_components) {
        let num_rue = adresse_selected.address_components.find( c => c.types.includes('street_number')),
            route = adresse_selected.address_components.find( c => c.types.includes('route')),
            code_postal = adresse_selected.address_components.find( c => c.types.includes('postal_code')),
            ville = adresse_selected.address_components.find( c => c.types.includes('locality'))

        num_rue = num_rue ? num_rue.long_name : ''
        route = route ? route.long_name : ''
        geoloc_code_postal = code_postal ? code_postal.long_name : ''
        geoloc_ville = ville ? ville.long_name : ''
        geoloc_adresse = num_rue + ' ' +route;
        
    } else {
        geoloc_adresse = 'Aucune adresse trouvée'
    }

    this.props.onChangeHandlerGps({
      geoloc_adresse,
      geoloc_adresse_complement,
      geoloc_code_postal,
      geoloc_ville,
      geoloc_latitude:this.props.gps_infos.geoloc_latitude.toFixed(6),
      geoloc_longitude:this.props.gps_infos.geoloc_longitude.toFixed(6)
    });
    // On ferme la modal le loader et la liste d'adresses.
    this.setState({showModalAdresse:false,loadding:false,adresses:null,id_adresse:null})
  }
  /**
   * Retourne la modal d'adresse
   */
  getModalAdresse(){
    return(
      <Modal
        openModal={this.state.showModalAdresse}
        onCloseHandler={()=>{this.setState({showModalAdresse:false,loadding:false,adresses:null,id_adresse:null})}}
        onSubmitHandler={this.sumbmitSelectAdresse.bind(this)}
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
           Choisissez l'adresse correspondante.
        </DialogTitle>
        <DialogContent key="content">
          <Grid item xs={12}>
            <TextInput
              select
              className={this.props.classes.input}
              label="Adresse"
              value={this.state.id_adresse}
              margin="normal"
              collectionStore={{}}
              name='id_adresse'
              onChangeHandler={this.onChangeHandlerAdresse.bind(this)}
              fullWidth
            >
              {this.getOptions()}
            </TextInput>
            </Grid>
        </DialogContent>
      </Modal>
    )
  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {mobilier, typesStore, finitionsStore} = this.props;

    if (!mobilier || !typesStore.list || !finitionsStore.list) return (
      <Grid item xs={12} style={{textAlign: 'center'}}>
        <CircularProgress/>
      </Grid>
    );

    return (
      <Grid item xs={12}>
        <Grid item xs={12}>
          {this.getGpsBtn()}
        </Grid>
        {this.getModalAdresse()}
      </Grid>
    );
  }
}

UpdateGeolocalisation = withStyles(MobilierCss)(UpdateGeolocalisation);

UpdateGeolocalisation = connect((store) => {
  return {
    typesStore: store.collections.types,
    finitionsStore: store.collections.finitions,
    gps_infos:store.collections.gps.detail,
    gps_error:store.collections.gps.errors,
  }
})(UpdateGeolocalisation);

UpdateGeolocalisation.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  mobilier: PropTypes.object.isRequired
};

export default UpdateGeolocalisation
