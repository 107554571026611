import {AppBar, Button, Dialog, Grid, IconButton, Toolbar,TextField, MenuItem} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {
  Create,
  CropDin,
  Delete,
  PanoramaFishEye,
  PanTool,
  Redo as RedoIcon,
  Remove,
  SettingsEthernet,
  ShowChart,
  TabUnselected,
  FormatSize as TextIcon,
  Undo as UndoIcon,
  ZoomIn,
  ZoomOut,
  DeleteForever as RemoveIcon,
  ArrowRightAlt,
  FileCopy as CopyIcon,
  PowerInput as PowerInputIcon
} from '@material-ui/icons';
import {SketchField, Tools} from '@oniti/react-sketch';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {TwitterPicker} from 'react-color';
import MobilierCss from './css/MobilierCss';
import {loadCollectionAttribute} from '../../../reducers/collectionsReducer'
import {connect} from 'react-redux';

let availableColors = [
  '#FFFFFF',
  '#000000',
  '#FF6900',
  '#FCB900',
  '#7BDCB5',
  '#00D084',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#EB144C',
  '#F78DA7',
  '#9900EF'
];
let uncontrolled_schema_fields = [
  'version',
  'scaleX',
  'scaleY',
  'styles'
];

class UpdateSchema extends Component {
  state = {
    tool: Tools.Pencil,
    lineWidth: 3,
    lineColor: '#000000',
    backgroundColor: "#FFFFFF",
    controlledValue: null,
    schema_temoin:null,
    canUndo: false,
    canRedo: false,
    schema_uuid: null,
    openColorPicker: false,
    text: '',
    updated:false,
    current_zoom:1
  };

  componentDidMount(){
    loadCollectionAttribute(this.props.dispatch, 'list', 'schema_images', this.props.schemaImageStore);
  }
  
  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const {schema} = nextProps.mobilier;
    if (!!schema && schema.uuid !== prevState.schema_uuid) {
      return {
        schema_uuid: schema.uuid,
        controlledValue: schema.json ? JSON.parse(schema.json) : null,
        schema_temoin:schema.json ? JSON.parse(schema.json) : null
      };
    } else if (!schema) {
      return {
        schema_uuid: null,
        controlledValue: null,
        schema_temoin:null
      };
    }
    return null;
  }

  /**
   * Changement d'outil
   * @param  {[type]} tool [description]
   * @return {[type]}      [description]
   */
  onChangeTool(tool) {
    this.setState({
      tool
    })
  }

  /**
   * Modifications sur sketch
   * @return {[type]} [description]
   */
  onSketchChange() {
    if (!!this._sketch) {
      const {onChangeHandler} = this.props;
      let prev = this.state.canUndo;
      let now = this._sketch.canUndo();

      if(this.state.tool === Tools.Text) this.setState({
        tool:Tools.Select
      });
      let new_json = this._sketch.toJSON();

      //Vérifie si le schéma a été mis a jour avant de propager les modifications.
      if(this.hasSchemaChange(new_json)){
        onChangeHandler(JSON.stringify(new_json), this._sketch.toDataURL());
        // eslint-disable-next-line
        this.state.schema_temoin = new_json
      }
      

      if (prev !== now) {
        this.setState({canUndo: now});
      }
    }
  }


  hasSchemaChange(new_schema_json){
    if(!this.state.schema_temoin) return true;
    let objects_controlled_value = this.state.schema_temoin.objects,
        objects_new_schema_json = new_schema_json.objects;

    if(objects_controlled_value.length !== objects_new_schema_json.length) return true;

    return objects_controlled_value.some((value,index) => {
      let keys = Object.keys(value).filter( k => uncontrolled_schema_fields.indexOf(k) === -1);
      //Filtrage Complémentaire pour les textbox
      // qui sont redimentionner au chargement malgré la désactivation de l'event autosize
      if(value['type'] === 'textbox'){
        keys = keys.filter(k => k !== 'width' && k!== 'height')
      }
      return keys.some( key => {
        // Compare chaque clef pour savoir si elles sont différente a condition que la clef du nouveau schéma existe bien
        return value[key] !== objects_new_schema_json[index][key] && !! objects_new_schema_json[index][key]
      })

    })
    
  }

  /**
   * Efface le schema
   * @return {[type]} [description]
   */
  clear() {
    this._sketch.clear();
    this.setState({
      controlledValue: null,
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo()
    })
  }

  /**
   * Annule la derniere action
   * @return {[type]} [description]
   */
  undo() {
    this._sketch.undo();
    this.setState({
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo()
    })
  }

  /**
   * Remet la dernière action annulée
   * @return {[type]} [description]
   */
  redo() {
    this._sketch.redo();
    this.setState({
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo()
    })
  }

  /**
   * Handler Change Controllers
   * @param name
   * @param e
   * @param value
   */
  onChangeLineWeight(name, e, value) {
    this.setState({
      [name]: value ? value : e.target.value
    })
  }

  /**
   * Renvoi la modale avec le Color Picker
   * @returns {*}
   */
  getColorPicker() {
    return (
      <Dialog
        open={this.state.openColorPicker}
        onClose={this.handleCloseColorPicker.bind(this)}
      >
        <TwitterPicker
          width='170px'
          color={this.state.lineColor}
          onChange={this.handleChangeColorPicker.bind(this)}
          colors={availableColors}
        />
      </Dialog>
    )
  }

  /**
   * Actions sur sélection de couleur dans le Color Picker
   * @param color
   * @param event
   */
  handleChangeColorPicker(color, event) {
    this.setState({
      ...this.state,
      lineColor: color.hex,
      openColorPicker: false
    });
  };

  /**
   * Actions de fermeture du Color Picker
   */
  handleCloseColorPicker() {
    this.setState({
      ...this.state,
      openColorPicker: false
    });
  }

  /**
   * Ouvre le Color Picker
   */
  openColorPicker() {
    this.setState({
      ...this.state,
      openColorPicker: true
    })
  }

  _addText = () => {
    this._sketch.addText(this.state.text);
    this.setState({
      text:'',
      tool:Tools.Select
    })
  };

  _removeSelected = () => {
    this._sketch.removeSelected()
  };

  onChangeImage(e){
    let {value} = e.target;
    this._sketch.addImg(value);
    this.setState({
      tool:Tools.Select
    })
  }


  getOptionImages(){
    if(!this.props.schemaImageStore.list) return [];

    return this.props.schemaImageStore.list.map(schema_image => (
      <MenuItem key={schema_image.uuid} value={schema_image.base_64}>
        <img src={schema_image.base_64} width={'25px'} style={{marginRight: '1em'}} alt=""/> {schema_image.nom}
      </MenuItem>
    ))
  }

  zoomInOut(factor){
    let next_zoom = this.state.current_zoom * factor,
        update_canvas = true
    if(next_zoom > 1){
      next_zoom = 1
      update_canvas = false
    }
    else if(next_zoom < 0.5){
      next_zoom = 0.5
      update_canvas = false
    }
    this.setState({current_zoom:next_zoom},()=>{
      if(update_canvas) this._sketch.zoom(factor)
    })
  }

  getAppBar(){
    const {classes} = this.props,
          {tool} = this.state;
    return(
      <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              onClick={this.onChangeTool.bind(this, Tools.Select)}
              className={(tool === Tools.Select ? classes.active : classes.noactive)}
              title="Déplacer un élément"
            >
              <TabUnselected/>
            </IconButton>
            <IconButton
              onClick={this.onChangeTool.bind(this, Tools.Pan)}
              className={(tool === Tools.Pan ? classes.active : classes.noactive)}
              title="Déplacer"
            >
              <PanTool/>
            </IconButton>
            <IconButton
              onClick={this.onChangeTool.bind(this, Tools.Circle)}
              className={(tool === Tools.Circle ? classes.active : classes.noactive)}
              title="Cercle"
            >
              <PanoramaFishEye/>
            </IconButton>
            <IconButton
              onClick={this.onChangeTool.bind(this, Tools.Rectangle)}
              className={(tool === Tools.Rectangle ? classes.active : classes.noactive)}
              title="Rectangle"
            >
              <CropDin/>
            </IconButton>
            <IconButton
              onClick={this.onChangeTool.bind(this, Tools.Line)}
              className={(tool === Tools.Line ? classes.active : classes.noactive)}
              title="Ligne"
            >
              <ShowChart/>
            </IconButton>
            <IconButton
              onClick={this.onChangeTool.bind(this, Tools.DasedLine)}
              className={(tool === Tools.DasedLine ? classes.active : classes.noactive)}
              title="Ligne pointillée"
            >
              <PowerInputIcon/>
            </IconButton>
            <IconButton
              onClick={this.onChangeTool.bind(this, Tools.HorizontalLine)}
              className={(tool === Tools.HorizontalLine ? classes.active : classes.noactive)}
              title="Ligne Horizontale"
            >
              <Remove/>
            </IconButton>
            <IconButton
              onClick={this.onChangeTool.bind(this, Tools.VerticalLine)}
              className={classes.verticaleline + ' ' + (tool === Tools.VerticalLine ? classes.active : classes.noactive)}
              title="Ligne Verticale"
            >
              <Remove/>
            </IconButton>
            <IconButton
              onClick={this.onChangeTool.bind(this, Tools.DoubleArrow)}
              className={(tool === Tools.DoubleArrow ? classes.active : classes.noactive)}
              title="Double Flêche"
            >
              <SettingsEthernet/>
            </IconButton>
            <IconButton
              onClick={this.onChangeTool.bind(this, Tools.Arrow)}
              className={(tool === Tools.Arrow ? classes.active : classes.noactive)}
              title="Flêche"
            >
              <ArrowRightAlt/>
            </IconButton>
            <IconButton
              onClick={this.onChangeTool.bind(this, Tools.Text)}
              className={(tool === Tools.Text ? classes.active : classes.noactive)}
              title="Ajouter le texte au schéma"
            >
              <TextIcon/>
            </IconButton>
            <IconButton
              onClick={this.onChangeTool.bind(this, Tools.Pencil)}
              className={(tool === Tools.Pencil ? classes.active : classes.noactive)}
              title="Crayon"
            >
              <Create/>
            </IconButton>

            <IconButton
              onClick={this.zoomInOut.bind(this,0.8)}
              title="Zoom Out"
              disabled={this.state.current_zoom <= 0.5}
            >
              <ZoomOut/>
            </IconButton>
            <IconButton
              onClick={this.zoomInOut.bind(this,1.25)}
              title="Zoom In"
              disabled={this.state.current_zoom >= 1}
            >
              <ZoomIn/>
            </IconButton>
            <IconButton
              onClick={this.undo.bind(this)}
              disabled={!this.state.canUndo}
              title="Annuler"
            >
              <UndoIcon/>
            </IconButton>
            <IconButton
              onClick={this.redo.bind(this)}
              disabled={!this.state.canRedo}
              title="Remettre"
            >
              <RedoIcon/>
            </IconButton>
            <IconButton
              title='Copie la sélection'
              onClick={(e) => {
                this._sketch.copy();
                this._sketch.paste();
              }}>
              <CopyIcon/>
            </IconButton>
            <IconButton
              onClick={this._removeSelected}
              title="Supprimer la sélection"
              >
              <RemoveIcon/>
            </IconButton>

            <IconButton
              onClick={this.clear.bind(this)}
              title="Effacer tout"
            >
              <Delete/>
            </IconButton>
            <Button
              onClick={this.openColorPicker.bind(this)}
              style={{backgroundColor: this.state.lineColor}}
              className={classes.colorPickerButton}
            >
              {this.state.lineColor}
            </Button>
            <TextField
              id="select_image"
              select
              label="Image"
              onChange={this.onChangeImage.bind(this)}
              margin="normal"
              value={''}
              fullWidth={true}
            >
              {this.getOptionImages()}
            </TextField>
          </Toolbar>
        </AppBar>
    )
  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {classes} = this.props,
          {tool, lineColor, lineWidth, backgroundColor, controlledValue} = this.state;

    return (
      <Grid item xs={12} className={classes.schemaContainer}>
        {this.getAppBar()}
        <SketchField
          className={classes.sketchContainer}
          ref={(c) => this._sketch = c}
          onChange={this.onSketchChange.bind(this)}
          tool={tool}
          height={'600px'}
          // width={'950px'}
          value={controlledValue}
          backgroundColor={backgroundColor}
          lineColor={lineColor}
          lineWidth={lineWidth}
        />
        {this.getColorPicker()}
      </Grid>
    );
  }
}

UpdateSchema = withStyles(MobilierCss)(UpdateSchema);

UpdateSchema.propTypes = {
  mobilier: PropTypes.object,
  onChangeHandler: PropTypes.func.isRequired
};

UpdateSchema = connect((store) => {
  return {
    schemaImageStore: store.collections.schema_images
  }
})(UpdateSchema);

export default UpdateSchema
