export default theme => ({
  root: {
    maxWidth: '100%'
  },
  defaultBackgroundIcon: {
    color: '#cfd3d6',
  },
  inputFile: {
    display: 'none'
  },
  imagepreview:{
    cursor:'pointer'
  },
  rowFile:{
    textAlign:'center',
    marginTop:'1em'
  },
  thNom:{
    width:'80%'
  },
  logoPreview: {
    maxWidth: '100%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    left: '50%',
    fontSize: '13em',
    color: '#cfd3d6'
  },
  addButton: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 2,
    color:'#ECEFF1'
  },
  addButtonIcon: {
    fontSize: 32
  },
});
