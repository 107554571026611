import {
  Fab,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {Add, CloudDownload, Delete, Edit, PictureAsPdf, Settings} from '@material-ui/icons';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import MobilierIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import Done from '@material-ui/icons/Done';
import {Cell, DataTable} from '@oniti/datatable-material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {collectionActions} from "../../../reducers/collectionsReducer";
import {massUpdateMobiliers} from './actions/ProjetActions'
import TextInput from '../../common/Components/TextInput';
import CreateModal from '../Mobilier/Create';
import UpdateModal from '../Mobilier/Update';
import ProjetCss from './css/ProjetCss';
import Modal from '../../common/Components/Modal';

class Detail extends Component {
  state = {
    projet: null,
    listeMobilier: null,
    openCreateModal: false,
    openUpdateModal: false,
    openDeleteModal: false,
    mobilier: null,
    loading:false
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const {mobiliersStore, projetsStore} = nextProps;

    if (mobiliersStore.list !== prevState.listeMobilier) {
      return {
        listeMobilier: mobiliersStore.list
      };
    }

    if (!prevState.projet && projetsStore.detail !== prevState.projet) {
      return {
        projet: projetsStore.detail
      };
    }

    return null;
  }

  componentDidMount() {
    let {dispatch} = this.props;
    collectionActions(dispatch, 'mobiliers', 'INDEX', {params: {projet_uuid: this.props.match.params.uuid}});
    collectionActions(dispatch, 'projets', 'SHOW', {uuid: this.props.match.params.uuid})
  }

  componentWillUnmount() {
    collectionActions(this.props.dispatch, 'projets', 'RESET_ERRORS');
  }

  onChangeProjetHandler(name, event) {
    let {value} = event.target;

    this.setState({
      projet: {
        ...this.state.projet,
        [name]: value
      }
    });
  }

  onClickUpdateProjetHandler() {
    collectionActions(this.props.dispatch, 'projets', 'UPDATE', this.state.projet)
  }

  onKeyPressProjetHandler(e) {
    if (e.which === 13 || e.keyCode === 13 || e.key === 'Enter') { // Différents modes de détection pour prendre en charge l'ensemble des navigateurs
      this.onClickUpdateProjetHandler()
    }
  }

  onClickPdfPiquetage(mobilier) {
    window.open(process.env.REACT_APP_API_URL + '/mobiliers/' + mobilier.uuid + '/piquetage', '_blank');
  }

  onClickEditHandler(mobilier) {
    this.setState({openUpdateModal: true, mobilier: mobilier});
  }

  onClickDeleteHandler(mobilier) {
    this.setState({openDeleteModal: true, mobilier: mobilier})
  }

  onSubmitDeleteHandler(mobilier) {
    collectionActions(this.props.dispatch, 'mobiliers', 'DELETE', {uuid: mobilier.uuid});
    this.setState({openDeleteModal: false, mobilier: null})
  }

  /**
   * Bouton d'ajout d'un utilisateur
   * @return {[type]} [description]
   */
  getBtnAdd() {
    let {classes} = this.props;

    return (
      <Fab
        color="primary"
        aria-label="add"
        size="small"
        onClick={() => {
          this.setState({openCreateModal: true})
        }}
        className={classes.addButton}
        title="Ajouter"
      >
        <Add style={{fontSize: 32}}/>
      </Fab>
    );
  }

  getActionsBtn(mobilier) {
    const {classes} = this.props;

    return [
      <IconButton
        aria-label="Delete"
        onClick={this.onClickDeleteHandler.bind(this, mobilier)}
        className={classes.button}
        key="delete"
        title="Supprimer"
      >
        <Delete/>
      </IconButton>,
      <IconButton
        aria-label="Edit"
        key="edit"
        color="primary"
        onClick={this.onClickEditHandler.bind(this, mobilier)}
        className={classes.button}
        title="Éditer"
      >
        <Edit/>
      </IconButton>,
      <IconButton
        aria-label="Piquetage"
        onClick={this.onClickPdfPiquetage.bind(this, mobilier)}
        className={classes.button}
        key="pdf-piquetage"
        title="Exporter la fiche de piquetage"
      >
        <PictureAsPdf/>
      </IconButton>
    ];
  }

  onClickReturnHandler(route) {
    this.props.history.push(route)
  }

  onChangeUpdateMobilier(e){
    let file = e.target.files[0],
        data = new FormData();
    data.append("file",file);
    this.setState({loading:true},()=>{
      massUpdateMobiliers(this.props.dispatch,this.props.projetsStore.detail.uuid,data,()=>{
        this.setState({loading:false})
      })
    })
    
  }

  getMobilierTitle() {
    const {classes} = this.props;
    return (
      <Typography
        variant="button"
        gutterBottom
        className={classes.mobiliersTitle}
      >
        Mobiliers
      </Typography>
    )
  }

  /**
   * Gère le changement de type dans le select
   * @param event
   */
  changeTypeHandler = event => {
    this.setState({
      projet: {
        ...this.state.projet,
        type: event.target.value
      }
    })
  };

  render() {
    const {classes, projetsStore} = this.props;

    if (!this.state.projet || this.state.loading) return <CircularProgress/>;

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography
            variant="button"
            gutterBottom
            className={classes.projetTitle}
          >
            Projet
          </Typography>
          <Button
            title='Retour à la liste des projets'
            color='primary'
            className={classes.returnButton}
            onClick={this.onClickReturnHandler.bind(this, '/marches/' + this.state.projet.marche_uuid)}
          >
            <ChevronLeft/> Retour
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="libelle"
            label="Libellé"
            className={classes.labelInput}
            value={this.state.projet.libelle}
            margin="normal"
            collectionStore={projetsStore}
            name='libelle'
            onChangeHandler={this.onChangeProjetHandler.bind(this)}
            onKeyPress={this.onKeyPressProjetHandler.bind(this)}
          />
          <TextInput
            id="contact"
            label="Contact"
            className={classes.labelInput}
            value={this.state.projet.contact}
            margin="normal"
            collectionStore={projetsStore}
            name='contact'
            onChangeHandler={this.onChangeProjetHandler.bind(this)}
            onKeyPress={this.onKeyPressProjetHandler.bind(this)}
          />
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="type-helper">Type</InputLabel>
            <Select
              className={classes.labelInput}
              value={this.state.projet.type}
              onChange={this.changeTypeHandler}
            >
              <MenuItem value='mep_initiale'>Mise en place initiale</MenuItem>
              <MenuItem value='exploitation'>Exploitation</MenuItem>
            </Select>
          </FormControl>

          <Button
            title='Valider les changements'
            color='primary'
            className={classes.validButton}
            variant="contained"
            size="small"
            onClick={this.onClickUpdateProjetHandler.bind(this)}
          >
            <Done/>
          </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              title='Télécharger les fiches de piquetage'
              color='primary'
              variant="contained"
              className={classes.exportPiquetageButton}
              onClick={() => {
                window.open(process.env.REACT_APP_API_URL + '/projets/' + this.state.projet.uuid + '/piquetage');
              }}
              disabled={!this.state.listeMobilier || !(this.state.listeMobilier && this.state.listeMobilier.length > 0)}
            >
              <CloudDownload style={{marginRight: '8px'}}/> Fiches de piquetage
            </Button>
            <Button
              title='Télécharger un export des mobiliers'
              color='primary'
              variant="contained"
              className={classes.exportPiquetageButton}
              onClick={() => {
                window.open(process.env.REACT_APP_API_URL + '/projets/' + this.state.projet.uuid + '/mobiliers');
              }}
              disabled={!this.state.listeMobilier || !(this.state.listeMobilier && this.state.listeMobilier.length > 0)}
            >
              <MobilierIcon style={{marginRight: '8px'}}/> Export des mobiliers
            </Button>
            <form style={{display:'inline-block'}}>
              <Button
                title='Mise à jour des mobiliers'
                color='primary'
                variant="contained"
                onClick={()=>{
                  document.getElementById('file_moblier_update').click()
                }}
              >
                <Settings style={{marginRight: '8px'}}/> Mise à jour des mobiliers
              </Button>
              <input 
                type='file' 
                style={{display:'none'}} 
                id='file_moblier_update'
                accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={this.onChangeUpdateMobilier.bind(this)}
              ></input>
            </form>
          <Divider className={classes.divider}/>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <DataTable
                datas={this.state.listeMobilier ? this.state.listeMobilier : []}
                showPagination={true}
                showSearch={true}
                defaultSort="general_num_fiche"
                sortType='desc'
                rowsPerPageOptions={[50, 25, 10]}
                extraNodes={[
                  {
                    element: this.getBtnAdd(),
                    position: 'top-right'
                  },
                  {
                    element: this.getMobilierTitle(),
                    position: 'top-left'
                  }
                ]}
              >
                <Cell
                  datakey='general_num_fiche'
                  title='N° Fiche'
                  searchable={true}
                  sortable={true}
                />
                <Cell
                  datakey='type_futur_nom'
                  title='Type'
                  searchable={true}
                  sortable={true}
                />
                <Cell
                  datakey='geoloc_adresse'
                  title='Adresse'
                  searchable={true}
                  sortable={true}
                />
                <Cell
                  datakey='geoloc_ville'
                  title='Ville'
                  searchable={true}
                  sortable={true}
                />
                <Cell
                  datakey='geoloc_nom_arret'
                  title='Nom Arrêt'
                  searchable={true}
                  sortable={true}
                />
                <Cell
                  datakey='geoloc_code_emplacement'
                  title='Code Emplacement'
                  searchable={true}
                  sortable={true}
                />
                <Cell
                  format={this.getActionsBtn.bind(this)}
                  className={classes.lastColumn}
                />
              </DataTable>
            </Grid>
          </Grid>
        </Grid>
        <CreateModal
          onCloseHandler={() => {
            this.setState({openCreateModal: false})
          }}
          open={this.state.openCreateModal}
          projet={this.state.projet}
        />
        <UpdateModal
          onCloseHandler={() => {
            this.setState({openUpdateModal: false, mobilier: null})
            collectionActions(this.props.dispatch, 'mobiliers','RESET_DETAIL')
          }}
          open={this.state.openUpdateModal}
          mobilier_uuid={this.state.mobilier ? this.state.mobilier.uuid : null}
        />
        <Modal
          openModal={this.state.openDeleteModal}
          onCloseHandler={() => {
            this.setState({openDeleteModal: false, mobilier: null})
          }}
          onSubmitHandler={this.onSubmitDeleteHandler.bind(this, this.state.mobilier)}
          fullWidth={true}
          maxWidth='sm'
        >
          <DialogTitle id="confirmation-dialog-title">
            <MobilierIcon/> <span>{'Suppression du mobilier'}</span>
          </DialogTitle>
          <DialogContent>
            <Typography>{'Êtes vous sûr de vouloir supprimer ce mobilier ?'}</Typography>
          </DialogContent>
        </Modal>
      </Grid>
    );
  }
}

Detail = connect((store) => {
  return {
    mobiliersStore: store.collections.mobiliers,
    projetsStore: store.collections.projets
  }
})(Detail);

Detail = withStyles(ProjetCss)(Detail);

export default Detail
